export function CloseIcon({ scale = 1, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12 * scale}
      height={12 * scale}
      fill="none"
      viewBox="0 0 8 8"
      style={style}
    >
      <path
        stroke="#1B211D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
        d="M6 2L2 6M2 2l4 4"
      ></path>
    </svg>
  );
}
