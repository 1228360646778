import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export type SidebarScreen = "song-list" | "notifications";

interface SidebarState {
  isOpen: boolean;
  toggleOpen: (s?: boolean) => void;
  close: () => void;
  open: () => void;
  screen: SidebarScreen;
  setScreen: (screen: SidebarScreen) => void;
}

export const useSidebarStore = create<SidebarState>()(
  devtools(
    persist(
      (set) => ({
        isOpen: true,
        toggleOpen: (isOpen) =>
          set((state) => ({
            isOpen: isOpen !== undefined ? isOpen : !state.isOpen,
          })),
        close: () => set({ isOpen: false }),
        open: () => set({ isOpen: true }),
        screen: "song-list",
        setScreen: (screen) => set({ screen }),
      }),
      {
        name: "sidebar-state-storage",
      },
    ),
  ),
);
