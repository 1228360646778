export const config = {
  isDev: import.meta.env.DEV,
  hp: {
    url: import.meta.env.VITE_HP_URL || "ws://localhost:3005/",
  },
  api: {
    url: import.meta.env.VITE_API_URL || "http://localhost:3005",
  },
  auth: {
    public_key: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY || "",
  },
};

export function checkConfig() {
  let hasError = false;
  if (!import.meta.env.VITE_HP_URL) {
    console.error("Missing VITE_HP_URL");
    hasError = true;
  }
  if (!import.meta.env.VITE_API_URL) {
    console.error("Missing VITE_API_URL");
    hasError = true;
  }
  if (!import.meta.env.VITE_CLERK_PUBLISHABLE_KEY) {
    console.error("Missing VITE_CLERK_PUBLISHABLE_KEY");
    hasError = true;
  }

  if (hasError) {
    throw new Error("Missing config");
  }
}
