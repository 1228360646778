import { Input } from "@songleaf/ui";
import { useEffect, useState } from "react";
import { useCurrentSong, useUpdateSong } from "../../api/songs";

export const SongTitleInput: React.FC = () => {
  const { song, role } = useCurrentSong();

  const [title, setTitle] = useState<string>("");

  // wtf do I need this? once trpc cache kicks in the default state doesn't change above
  useEffect(() => {
    setTitle(song?.title || "");
  }, [song?.title]);

  const { mutate: updateSong } = useUpdateSong(song?.id);

  if (role !== "owner" && role !== "writer") {
    return <div className="pb-2 text-sm font-extrabold">{title}</div>;
  }

  return (
    <Input
      className="w-full py-1 pl-1 !text-sm !font-extrabold"
      containerClassName="flex-1 !pb-0"
      variant="invisible"
      value={title}
      onChange={(e) => setTitle(e.target.value)}
      onBlur={(e) => {
        if (!song || e.target.value === song.title) {
          return;
        }

        updateSong({
          songId: song?.id || "",
          title: e.target.value,
        });
      }}
    />
  );
};
