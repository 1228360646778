import { Sidebar } from "@songleaf/ui";
import { Trash2 } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { trpc } from "../../../_utils/trpc";
import { useSidebarStore } from "../../../state/sidebar";
import { useDeleteSong } from "../../../api/songs";
import { ArtworkImage } from "@/components/artwork-image";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { WritersList } from "@/components/writers-list";

interface HomeSidebarSongListitemProps {
  song: {
    id: string;
    title: string;
    artworkId?: string | null;
    role?: string | null;
    writers?: string | null;
  };
}

export const HomeSidebarSongListitem = ({
  song,
}: HomeSidebarSongListitemProps) => {
  const { songId } = useParams();
  const navigate = useNavigate();
  const tutils = trpc.useContext();

  const { close: closeSidebar } = useSidebarStore();

  const { mutate: deleteSong } = useDeleteSong(songId);

  const onListItemClick = (id: string) => {
    tutils.song.byId.invalidate(id);
    navigate(`/song/${id}`);

    // Close sidebar on mobile
    if (window.innerWidth < 768) {
      closeSidebar();
    }
  };

  return (
    <Sidebar.ListItem
      active={song.id === songId}
      actionIcon={<EllipsisVerticalIcon className="h-8 w-4" />}
      onClick={() => onListItemClick(song.id)}
      actions={[
        {
          key: `${song.id}-listitem-delete`,
          type: "basic",
          label: "Delete",
          color: "danger",
          onClick: () => deleteSong(song.id),
          icon: <Trash2 size={12} />,
        },
      ]}
    >
      <div className="flex space-x-2 py-0.5">
        <ArtworkImage
          imageId={song.artworkId}
          size="32x32"
          className="h-8 w-8 min-w-[2rem] rounded"
        />
        <div className="flex flex-col justify-center">
          <p className="font-medium">{song.title}</p>
          <WritersList
            writers={song.writers}
            className="text-[0.625rem] opacity-70"
          />
        </div>
      </div>
    </Sidebar.ListItem>
  );
};
