import React from "react";
import classnames from "classnames";
import * as RadixProgress from "@radix-ui/react-progress";
import { ProgressProps as RadixProgressProps } from "@radix-ui/react-progress";

export interface ProgressProps extends RadixProgressProps {
  loading?: boolean;
}

const rootClassnames = classnames(
  "adiago-progress",
  "translate-z relative overflow-hidden h-1 w-full rounded-full bg-neutral-200 dark:bg-neutral-700",
);

export const Progress: React.FC<ProgressProps> = ({ loading, ...props }) => {
  const indicatorClassnames = classnames(
    "adiago-progress-indicator",
    "bg-surface-brand w-full h-full rounded-full transition-all",
    {
      "animate-[pulse_750ms_ease-in-out_infinite]": loading,
    },
  );

  return (
    <RadixProgress.Root {...props} className={rootClassnames}>
      <RadixProgress.Indicator
        className={indicatorClassnames}
        style={{ transform: `translateX(-${100 - (props.value || 100)}%)` }}
      />
    </RadixProgress.Root>
  );
};

Progress.displayName = "Progress";
