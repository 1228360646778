import React from "react";
import { Toaster } from "sonner";

// import { AdiagoNativeAudioContext } from "./native-audio-context";
import { AdiagoTheme } from "./theme";
import Tooltip from "../../tooltip";

window.AudioContext = window.AudioContext || (window as any).webkitAudioContext;

export interface AdiagoRootProps {
  children: React.ReactNode;
}

export const AdiagoRoot: React.FC<AdiagoRootProps> = ({ children }) => {
  return (
    <>
      {/* <AdiagoNativeAudioContext.Provider value={new AudioContext()}> */}
      <Tooltip.Provider>
        <AdiagoTheme />
        {children}
      </Tooltip.Provider>
      <Toaster position="bottom-right" />
      {/* </AdiagoNativeAudioContext.Provider> */}
    </>
  );
};
