import { useAudioStore } from "@/state/audio";
import { Audio } from "@songleaf/ui";

export const EditorToolbarMicInput: React.FC = () => {
  const { preferredMic, setPreferredMic } = useAudioStore();

  return (
    <div
      className="relative z-20 flex w-full flex-col justify-between space-y-2 rounded-lg bg-surface-base transition-all ease-out animate-in fade-in slide-in-from-bottom-3"
      contentEditable={false}
    >
      <div className="w-full px-0.5 py-0.5">
        <Audio.MicSelect
          shouldRefetch
          deviceId={preferredMic}
          setDeviceId={setPreferredMic}
        />
      </div>
    </div>
  );
};
