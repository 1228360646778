import React from "react";
import classnames from "classnames";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";
import * as RadixSelect from "@radix-ui/react-select";
import {
  SelectTriggerProps as RadixSelectTriggerProps,
  // SelectValueProps as RadixSelectValueProps,
  // SelectIconProps as RadixSelectIconProps,
  SelectContentProps as RadixSelectContentProps,
  SelectViewportProps as RadixSelectViewportProps,
  // SelectGroupProps as RadixSelectGroupProps,
  SelectItemProps as RadixSelectItemProps,
  // SelectItemTextProps as RadixSelectItemTextProps,
  // SelectItemIndicatorProps as RadixSelectItemIndicatorProps,
  SelectLabelProps as RadixSelectLabelProps,
  SelectSeparatorProps as RadixSelectSeparatorProps,
} from "@radix-ui/react-select";
import { ChevronDown, ChevronUp } from "lucide-react";

export const SelectRoot = RadixSelect.Root;
export const SelectValue = RadixSelect.Value;
export const SelectIcon = RadixSelect.Icon;
export const SelectGroup = RadixSelect.Group;
export const SelectItemText = RadixSelect.ItemText;

export interface SelectTriggerProps extends RadixSelectTriggerProps {
  classOverride?: string;
  iconOverride?: React.ReactNode;
  iconClassNameOverride?: string;
  placeholder?: string;
  leftIcon?: React.ReactNode;
  valueOverride?: React.ReactNode;
}
export const SelectTrigger: React.FC<SelectTriggerProps> = ({
  className,
  classOverride,
  iconOverride,
  iconClassNameOverride,
  placeholder,
  leftIcon,
  valueOverride,
  ...props
}) => {
  const classNames =
    classOverride ??
    classnames(
      "adiago-select-trigger px-2 py-1 flex items-center justify-between space-x-2 min-w-[4rem] text-sm rounded shadow-sm border border-surface-level-2 bg-white focus-visible:outline-surface-brand",
      className,
    );

  const iconClassNames =
    iconClassNameOverride ??
    classnames(
      "adiago-select-icon w-4 text-neutral-800 dark:text-white",
      className,
    );

  return (
    <RadixSelect.Trigger {...props} className={classNames}>
      {leftIcon}
      {valueOverride || (
        <SelectValue placeholder={placeholder || "Select..."} />
      )}
      <RadixSelect.SelectIcon className={iconClassNames}>
        {iconOverride ?? <ChevronDownIcon />}
      </RadixSelect.SelectIcon>
    </RadixSelect.Trigger>
  );
};

SelectTrigger.displayName = "SelectTrigger";

export interface SelectContentProps extends RadixSelectContentProps {
  classOverride?: string;
}
export const SelectContent: React.FC<SelectContentProps> = ({
  className,
  classOverride,
  ...props
}) => {
  const classNames =
    classOverride ??
    classnames(
      "adiago-select-content max-h-48 overflow-hidden rounded-lg shadow-xs drop-shadow-lg bg-surface-base",
      className,
    );

  return (
    <RadixSelect.Portal>
      <>
        <RadixSelect.Content
          position="popper"
          {...props}
          className={classNames}
        >
          <SelectScrollUpButton />
          <RadixSelect.Viewport>{props.children}</RadixSelect.Viewport>
          <SelectScrollDownButton />
        </RadixSelect.Content>
      </>
    </RadixSelect.Portal>
  );
};

SelectContent.displayName = "SelectContent";

export interface SelectViewportProps extends RadixSelectViewportProps {
  classOverride?: string;
}
export const SelectViewport: React.FC<SelectViewportProps> = ({
  className,
  classOverride,
  ...props
}) => {
  const classNames =
    classOverride ?? classnames("adiago-select-viewport p-1", className);

  return <RadixSelect.Viewport {...props} className={classNames} />;
};

SelectViewport.displayName = "SelectViewport";

export interface SelectItemProps extends RadixSelectItemProps {
  classOverride?: string;
  iconOverride?: React.ReactNode;
  subtitle?: string;
}
export const SelectItem: React.FC<SelectItemProps> = ({
  className,
  classOverride,
  iconOverride,
  title,
  subtitle,
  ...props
}) => {
  const classNames =
    classOverride ??
    classnames(
      `adiago-select-item py-1 px-2 w-full flex items-center justify-between space-x-3 relative text-sm rounded`,
      `transition duration-75 text-text-primary cursor-pointer focus:outline-none`,
      `hover:bg-surface-level-2 rx-disabled:text-text-primary-2 rx-disabled:pointer-events-none`,
      className,
    );

  return (
    <RadixSelect.Item {...props} className={classNames}>
      <RadixSelect.ItemText>
        <div>{title}</div>
        {subtitle && (
          <div className="text-xs text-text-primary-2">{subtitle}</div>
        )}
      </RadixSelect.ItemText>
      <RadixSelect.ItemIndicator className="w-3">
        {iconOverride ?? <CheckIcon />}
      </RadixSelect.ItemIndicator>
    </RadixSelect.Item>
  );
};

SelectItem.displayName = "SelectItem";

export interface SelectLabelProps extends RadixSelectLabelProps {
  classOverride?: string;
}
export const SelectLabel: React.FC<SelectLabelProps> = ({
  className,
  classOverride,
  ...props
}) => {
  const classNames =
    classOverride ??
    classnames(
      "adiago-select-label py-1 px-0.5 text-xs text-neutral-500 dark:text-neutral-400",
      className,
    );

  return <RadixSelect.Label {...props} className={classNames} />;
};

SelectLabel.displayName = "SelectLabel";

export interface SelectSeparatorProps extends RadixSelectSeparatorProps {
  classOverride?: string;
  iconOverride?: React.ReactNode;
}
export const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof RadixSelect.Label>,
  SelectSeparatorProps
>(({ className, classOverride, ...props }, forwardedRef) => {
  const classNames =
    classOverride ??
    classnames(
      "adiago-select-separator h-[1px] m-1 bg-surface-level-2",
      className,
    );

  return (
    <RadixSelect.Separator
      {...props}
      className={classNames}
      ref={forwardedRef}
    />
  );
});

SelectSeparator.displayName = "SelectSeparator";

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof RadixSelect.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof RadixSelect.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <RadixSelect.ScrollUpButton
    ref={ref}
    className={classnames(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    {...props}
  >
    <ChevronUp size={14} />
  </RadixSelect.ScrollUpButton>
));
SelectScrollUpButton.displayName = RadixSelect.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof RadixSelect.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof RadixSelect.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <RadixSelect.ScrollDownButton
    ref={ref}
    className={classnames(
      "flex cursor-default items-center justify-center py-0.5",
      className,
    )}
    {...props}
  >
    <ChevronDown size={14} />
  </RadixSelect.ScrollDownButton>
));
SelectScrollDownButton.displayName = RadixSelect.ScrollDownButton.displayName;
