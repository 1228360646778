import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AdiagoRoot } from "@songleaf/ui";
import "@songleaf/ui/src/index.css";
import "./index.css";

import { Router } from "./pages/_router";
import { AuthProvider } from "./components/auth/auth-provider";

import "./_utils/sentry";
import "./_utils/css";

// check config if needed
import { checkConfig } from "./_config";
import { VersionPill } from "./_utils/version";
checkConfig();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    {/* <ErrorBoundary> */}
    <BrowserRouter>
      <AuthProvider>
        <AdiagoRoot>
          <Router />
        </AdiagoRoot>
      </AuthProvider>
    </BrowserRouter>
    {/* </ErrorBoundary> */}

    <VersionPill />
  </React.StrictMode>,
);
