import { Loader, Select } from "@songleaf/ui";
import { TRPCOutputs } from "../../../_utils/trpc";
import { useCurrentSong } from "../../../api/songs";
import classnames from "classnames";
import { useState } from "react";
import {
  useCollaborators,
  useRemoveCollaborator,
  useRemoveInvite,
  useUpdateCollaborator,
  useUpdateInvite,
} from "../../../api/songInvites";
import { UserSongRole, isSongOwner } from "@/_utils/permissions";

export const CollaboratorList: React.FC = () => {
  const { songId, role } = useCurrentSong();

  const { data: collaborators, isLoading } = useCollaborators(songId);

  if (isLoading) {
    return (
      <div className="flex w-full items-center justify-center pt-4">
        <Loader size="sm" />
      </div>
    );
  }

  if (!collaborators?.length) {
    return (
      <div className="w-full pt-4 text-center text-xs italic text-text-secondary">
        No collaborators yet. Invite someone to collaborate on this song.
      </div>
    );
  }

  const collaboratorList = collaborators?.map((i, index) => (
    <CollaboratorListItem
      key={i.user_id || i.inviteId}
      collaborator={i}
      editable={isSongOwner(role)}
      index={index}
    />
  ));

  return (
    <div className="flex w-full flex-col space-y-1 px-1 py-2">
      {collaboratorList}
    </div>
  );
};

const userBackgroundColors = [
  "bg-friends-dan",
  "bg-friends-john",
  "bg-friends-alec",
  "bg-friends-rob",
  "bg-friends-mike",
];

interface CollaboratorListItemProps {
  index: number;
  collaborator: TRPCOutputs["song"]["collborators"][number];
  editable?: boolean;
}

export const CollaboratorListItem: React.FC<CollaboratorListItemProps> = ({
  index,
  collaborator,
  editable,
}) => {
  // const tutils = trpc.useContext();
  const userBackgroundColor =
    userBackgroundColors[index % userBackgroundColors.length];

  return (
    <div className="flex w-full items-center justify-between pt-2">
      <div className="flex items-center space-x-2">
        <div>
          {collaborator.user_id ? (
            <img
              src={collaborator.imageUrl || ""}
              alt={collaborator.fullName || ""}
              className="h-8 w-8 rounded-full"
            />
          ) : (
            <div
              className={classnames(
                "h-8 w-8 rounded-full",
                userBackgroundColor,
              )}
            />
          )}
        </div>
        <div className="flex flex-col items-start justify-center">
          <div className="flex items-center justify-center space-x-2">
            <div className="text-sm font-medium leading-none">
              {collaborator.fullName || collaborator.toAddress}
            </div>
            {collaborator.status === "invited" && (
              <div className="rounded-md bg-surface-level-2 px-2 py-0.5 text-2xs font-medium text-text-primary">
                Invited
              </div>
            )}
          </div>
          {!!collaborator.fullName && (
            <div className="text-xs text-gray-500">{collaborator.email}</div>
          )}
        </div>
      </div>
      <div className="flex items-center">
        {editable ? (
          <CollaboratorListRoleSelect
            collaborator={collaborator}
            index={index}
          />
        ) : (
          <div className="text-text-primary-light text-xs">
            {collaborator.role.charAt(0).toUpperCase() +
              collaborator.role.slice(1)}
          </div>
        )}
      </div>
    </div>
  );
};

export const CollaboratorListRoleSelect: React.FC<
  CollaboratorListItemProps
> = ({ collaborator }) => {
  const { songId } = useCurrentSong();
  const [role, setRole] = useState<string>(collaborator.role);

  const { mutate: updateCollaborator } = useUpdateCollaborator(songId);
  const { mutate: removeCollaborator } = useRemoveCollaborator(songId);
  const { mutate: updateInvite } = useUpdateInvite(songId);
  const { mutate: removeInvite } = useRemoveInvite(songId);

  return (
    <Select.Root
      value={role}
      onValueChange={(r: UserSongRole | "remove") => {
        if (!songId) return;

        if (r === "remove") {
          // remove collab or invite
          if (collaborator.inviteId) {
            removeInvite({
              songId: songId,
              inviteId: collaborator.inviteId,
            });
          } else if (collaborator.user_id) {
            removeCollaborator({
              songId: songId,
              userId: collaborator.user_id,
            });
          }
          return;
        }

        setRole(r);
        if (collaborator.inviteId) {
          updateInvite({
            songId: songId,
            inviteId: collaborator.inviteId,
            role: r,
          });
        } else {
          updateCollaborator({
            songId: songId,
            userId: collaborator.user_id,
            role: r,
          });
        }
      }}
    >
      <Select.Trigger
        placeholder="Permissions"
        classOverride="adiago-select-trigger px-2 py-1 flex items-center justify-between space-x-2 min-w-[4rem] text-xs text-text-primary-2 rounded focus-visible:outline-surface-level-2"
        iconClassNameOverride="adiago-select-icon w-4 text-text-primary-2"
        valueOverride={
          <Select.Value
            className="text-xs text-text-primary-2"
            placeholder="Permissions"
          >
            {role.charAt(0).toUpperCase() + role.slice(1)}
          </Select.Value>
        }
      />

      <Select.Content>
        <Select.Viewport>
          <Select.Group>
            <Select.Item
              value="owner"
              title="Owner"
              subtitle="Can edit and share with others."
            />

            <Select.Item
              value="writer"
              title="Writer"
              subtitle="Can edit, but not share with others."
            />
            <Select.Item
              value="viewer"
              title="Viewer"
              subtitle="Cannot edit or share with others."
            />
            <Select.Separator />
            <Select.Group>
              <Select.Item value="remove" title="Remove" />
            </Select.Group>
          </Select.Group>
        </Select.Viewport>
      </Select.Content>
    </Select.Root>
  );
};
