import { useEffect } from "react";

class PubSub<Payload, FN extends (p: Payload) => void = (p: Payload) => void> {
  topic: string;
  subscribers: FN[];

  constructor(topic: string) {
    // this is where we maintain list of subscribers for our PubSub
    this.topic = topic;
    this.subscribers = [];
  }

  subscribe(subscriber: FN) {
    // add the subscriber to existing list
    this.subscribers = [...this.subscribers, subscriber];
  }

  unsubscribe(subscriber: Function) {
    // remove the subscriber from existing list
    this.subscribers = this.subscribers.filter((sub) => sub !== subscriber);
  }

  publish(payload: Payload) {
    // publish payload to existing subscribers by invoking them
    this.subscribers.forEach((subscriber) => subscriber(payload));
  }
}

export const AudioPubSub = new PubSub<{ audioId: string }>("audio");

export const useAudioPubSub = (audioId: string) => {
  useEffect(() => {
    // subscribe to the AudioPubSub
    const sub = (payload: { audioId: string }) => {
      if (payload.audioId === audioId) {
        // do something with the payload
      }
    };
    AudioPubSub.subscribe(sub);

    // unsubscribe from the AudioPubSub
    return () => AudioPubSub.unsubscribe(sub);
  }, [audioId]);
};
