import { Button } from "@songleaf/ui";
import {
  useAllNotifications,
  useMarkAllNotificationsAsRead,
} from "@/api/notifications";
import { HomeSidebarNotificationListitem } from "./home-sidebar-notification-listitem";
import { HomeSidebarHeader } from "./home-sidebar-header";

export const HomeSidebarNotifications = () => {
  const { data: notifications } = useAllNotifications();
  const { mutate: markAllAsRead } = useMarkAllNotificationsAsRead();

  return (
    <>
      <HomeSidebarHeader />

      <div className="h-2" />

      <div className="flex items-center justify-between">
        <h1 className="cursor-pointer select-none text-lg font-bold">
          Notifications
        </h1>

        <div className="h-1" />
        <Button
          size="xs"
          variant="transparent"
          color="opaque"
          onClick={() => markAllAsRead()}
        >
          Mark all as read
        </Button>
      </div>

      <div className="h-1" />

      {notifications?.map((n) => (
        <HomeSidebarNotificationListitem key={n.id} notification={n} />
      ))}
    </>
  );
};
