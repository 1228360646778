import React from "react";
import classnames from "classnames";
import * as RadixScrollArea from "@radix-ui/react-scroll-area";

export interface ScrollAreaProps {
  children?: React.ReactNode;
  className?: string;
}

export const ScrollAreaVertical: React.FC<ScrollAreaProps> = ({
  children,
  className,
}) => {
  const classNames = classnames(
    "adiago-scroll-area overflow-hidden",
    className,
  );

  return (
    <RadixScrollArea.Root className={classNames}>
      <RadixScrollArea.Viewport className="h-full w-full">
        {children}
      </RadixScrollArea.Viewport>
      <RadixScrollArea.Scrollbar
        orientation="vertical"
        className="flex w-1 touch-none select-none rounded-full bg-neutral-200 p-[0.5] transition-all"
      >
        <RadixScrollArea.Thumb className="flex-1 rounded-full bg-neutral-400" />
      </RadixScrollArea.Scrollbar>
    </RadixScrollArea.Root>
  );
};

ScrollAreaVertical.displayName = "ScrollAreaVertical";
