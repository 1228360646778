export function NotificationIcon({ scale = 1, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24 * scale}
      height={24 * scale}
      fill="none"
      viewBox="0 0 24 24"
      style={style}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 4H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C3 6.28 3 7.12 3 8.8v7.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C5.28 21 6.12 21 7.8 21h7.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C20 18.72 20 17.88 20 16.2V13m-7 4H7m8-4H7m13.121-9.121A3 3 0 1115.88 8.12 3 3 0 0120.12 3.88z"
      ></path>
    </svg>
  );
}
