interface WritersListProps {
  writers?: string | null;
  className?: string;
}

export const WritersList: React.FC<WritersListProps> = ({
  writers,
  className,
}) => {
  return (
    <p className={className || "flex items-center space-x-1"}>
      {writers?.split(",").join(", ")}
    </p>
  );
};
