import { NodeViewProps } from "@tiptap/core";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";

export const SectionHeaderComponent: React.FC<NodeViewProps> = () => {
  return (
    <NodeViewWrapper className="section-header-node">
      <div className="section-header group/section">
        <NodeViewContent as="h2" />
      </div>
    </NodeViewWrapper>
  );
};
