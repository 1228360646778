import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import {
  RedirectToSignIn,
  SignIn,
  SignUp,
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";

import { HomeLayout } from "../layouts/home/home-layout";
import { HomePage } from "./home";
import { SongPage } from "./song";
import { MatrixLayout } from "./matrix/matrix-layout";
import { MatrixNotificationsPage } from "./matrix/notifications/notifications-page";
import { MatrixDBPage } from "./matrix/db/db-page";
import { ApiProvider } from "../components/api/api-provider";

export const Router = () => {
  return (
    <div className="app h-full">
      <Routes>
        <Route
          path="/sign-in/*"
          element={<SignIn routing="path" path="/sign-in" />}
        />
        <Route
          path="/sign-up/*"
          element={<SignUp routing="path" path="/sign-up" />}
        />

        <Route
          path="/"
          element={
            <>
              <SignedIn>
                <ApiProvider>
                  <Outlet />
                </ApiProvider>
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        >
          <Route element={<HomeLayout />}>
            <Route index element={<HomePage />} />
            {/* <Route path="song" element={<HomePage />} /> */}
            <Route path="song/:songId" element={<SongPage />} />
          </Route>

          <Route path="/matrix" element={<MatrixLayout />}>
            <Route path="/matrix/db" element={<MatrixDBPage />} />
            <Route
              path="/matrix/notifications"
              element={<MatrixNotificationsPage />}
            />
          </Route>

          <Route path="*" element={<Navigate to="/" replace={true} />}></Route>
        </Route>

        <Route
          path="*"
          element={
            <SignedOut>
              <RedirectToSignIn />
            </SignedOut>
          }
        />
      </Routes>
    </div>
  );
};
