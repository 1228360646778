import { trpc } from "../_utils/trpc";

export const useAllNotifications = () => {
  return trpc.notification.all.useQuery();
};

export const useUnreadNotificationCount = () => {
  return trpc.notification.unreadCount.useQuery();
};

export const useMarkAllNotificationsAsRead = () => {
  const tutils = trpc.useContext();
  return trpc.notification.markAllAsRead.useMutation({
    onMutate: () => {
      const notifications = tutils.notification.all.getData();
      if (!notifications) return;

      tutils.notification.all.setData(
        undefined,
        notifications.map((n) => {
          if (n.read_at === null) {
            return { ...n, read_at: new Date() };
          }
          return n;
        }),
      );

      tutils.notification.unreadCount.setData(undefined, 0);
    },
  });
};

export const useMarkNotificationAsRead = () => {
  const tutils = trpc.useContext();
  return trpc.notification.markAsRead.useMutation({
    onMutate: (id) => {
      const notifications = tutils.notification.all.getData();
      if (!notifications) return;
      const notification = notifications.find((n) => n.id === id);
      if (!notification) return;

      if (notification) {
        tutils.notification.all.setData(
          undefined,
          notifications.map((n) => {
            if (n.id === id) {
              return { ...n, read_at: new Date() };
            }
            return n;
          }),
        );
      }

      const count = tutils.notification.unreadCount.getData();

      if (count) {
        tutils.notification.unreadCount.setData(undefined, count - 1);
      }
    },
  });
};
