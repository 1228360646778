import { TestAccceptInviteNotification } from "./testAcceptInvite";
import { TestInviteNotification } from "./testInvite";

export const MatrixNotificationsPage = () => {
  return (
    <div>
      <TestInviteNotification />
      <br />
      <TestAccceptInviteNotification />
    </div>
  );
};
