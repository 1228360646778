import { UserNotificationPreferencesBackfill } from "./backfills/user-notification-preferences";
import { UserPreferencesBackfill } from "./backfills/user-preferences";

export const MatrixDBPage = () => {
  return (
    <div>
      <UserPreferencesBackfill />
      <br />
      <UserNotificationPreferencesBackfill />
    </div>
  );
};
