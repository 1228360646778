import { useEffect, useMemo, useState } from "react";
import { AnyExtension, useEditor } from "@tiptap/react";
import { Document } from "@tiptap/extension-document";
import { Text } from "@tiptap/extension-text";
import { Dropcursor } from "@tiptap/extension-dropcursor";
// import { Placeholder } from "@tiptap/extension-placeholder";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { IndexeddbPersistence } from "y-indexeddb";
import * as Y from "yjs";

import type { Song } from "@songleaf/db/schema";

import { useCurrentUser } from "@/api/users";
import { useGetTipTapToken } from "@/api/songs";

import { useCollabSocket } from "../api/collab-socket-provider";
import { LineNode } from "./nodes/line/line-node";
import { SectionNode } from "./nodes/section/section-node";
import { SectionHeaderNode } from "./nodes/section/section-header-node";
import { InlineAudioNode } from "./nodes/inline-audio/inline-audio-node";
import { AudioFileHandler } from "./file-extension";
import { Chords } from "./chords-extension";
import { Lines } from "./line-extension";
import { AudioDropHandler } from "./audio-drop-extension";

export interface SongEditorOptions {
  editable?: boolean;
}

export const useSongEditor = (
  song: Pick<Song, "id" | "title">,
  options?: SongEditorOptions,
) => {
  const ydoc = useMemo(() => new Y.Doc(), [song.id]);
  useMemo(() => new IndexeddbPersistence(song.id, ydoc), [song.id]);

  const { data: user } = useCurrentUser();
  const { data: tokenResponse, isLoading } = useGetTipTapToken(song.id);

  const socket = useCollabSocket();
  const [provider, setProvider] = useState<HocuspocusProvider>();

  useEffect(() => {
    if (!socket || !tokenResponse || !tokenResponse.token) {
      return;
    }

    const newProvider = new HocuspocusProvider({
      websocketProvider: socket,
      name: song.id,
      token: tokenResponse.token,
      document: ydoc,
    });

    setProvider(newProvider);

    return () => {
      newProvider?.destroy();
      setProvider(undefined);
    };
    // 👀 keeping an eye on this - migth want to just watch if song changes
  }, [socket, song.id, tokenResponse]);

  const editor = useEditor(
    {
      editable: options ? Boolean(options.editable) : false,
      extensions: [
        Document.extend({
          content: "section+",
        }),
        Text,
        Dropcursor.configure({
          color: "#36EA73",
          class: "editor-drop-cursor",
          width: 3,
        }),
        SectionNode,
        SectionHeaderNode,
        LineNode,
        InlineAudioNode,
        Lines,
        Chords,
        AudioDropHandler.configure({ songId: song.id }),
        AudioFileHandler.configure({ songId: song.id }),
        // Placeholder.configure({
        //   placeholder: ({ node }) => {
        //     console.log("placeholder", { node: node.type.name });

        //     if (node.type.name === "sectionHeader") {
        //       return "What’s the title?";
        //     }

        //     return "";
        //   },
        // }),
        Collaboration.configure({ document: ydoc }),
        provider
          ? CollaborationCursor.configure({
              provider,
              user: {
                name: user?.username,
                color: user?.preferences?.editorCursorColor || "#36EA73",
              },
            })
          : null,
      ].filter(
        (e: AnyExtension | null): e is AnyExtension => e !== null,
      ) as AnyExtension[],
    },
    [ydoc, provider?.configuration.name, options?.editable],
  );

  return { editor, isLoading };
};
