import React from "react";
import classnames from "classnames";
import * as RadixDialog from "@radix-ui/react-dialog";
import {
  DialogContentProps as RadixDialogContentProps,
  DialogTitleProps as RadixDialogTitleProps,
  DialogDescriptionProps as RadixDialogDescriptionProps,
} from "@radix-ui/react-dialog";

export const DialogPrimitive = RadixDialog;
export const DialogRoot = RadixDialog.Root;
export const DialogTrigger = RadixDialog.Trigger;
export const DialogClose = RadixDialog.Close;

export interface DialogContentProps extends RadixDialogContentProps {
  classOverride?: string;
  overlayClassName?: string;
  overlayClassOverride?: string;
}
export const DialogContent: React.FC<DialogContentProps> = ({
  className,
  classOverride,
  overlayClassName,
  overlayClassOverride,
  ...props
}) => {
  const classNames =
    classOverride ??
    classnames(
      "adiago-dialog-content z-50 fixed p-2 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-lg bg-surface-base shadow-sm rounded-xl data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-[52%] data-[state=closed]:slide-out-to-top-[52%] data-[state=open]:slide-in-from-left-[52%] data-[state=open]:slide-in-from-top-[52%]",
      className,
    );

  const overlayClassNames =
    overlayClassOverride ??
    classnames(
      "adiago-dialog-overlay z-50 fixed inset-0 bg-surface-invert-2 opacity-30 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      overlayClassName,
    );

  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay className={overlayClassNames} />
      <RadixDialog.Content {...props} className={classNames} />;
    </RadixDialog.Portal>
  );
};

DialogContent.displayName = "DialogContent";

export interface DialogTitleProps extends RadixDialogTitleProps {
  classOverride?: string;
}
export const DialogTitle: React.FC<DialogTitleProps> = ({
  className,
  classOverride,
  ...props
}) => {
  const classNames =
    classOverride ??
    classnames(
      "adiago-dialog-title font-bold text-base text-neutral-900 dark:text-white",
      className,
    );

  return <RadixDialog.Title {...props} className={classNames} />;
};

DialogTitle.displayName = "DialogTitle";

export interface DialogDescriptionProps extends RadixDialogDescriptionProps {
  classOverride?: string;
}
export const DialogDescription: React.FC<DialogDescriptionProps> = ({
  className,
  classOverride,
  ...props
}) => {
  const classNames =
    classOverride ??
    classnames(
      "adiago-dialog-title pt-1 text-sm text-neutral-600 dark:text-neutral-200",
      className,
    );

  return <RadixDialog.Title {...props} className={classNames} />;
};

DialogDescription.displayName = "DialogDescription";

export interface DialogButtonGroupProps {
  className?: string;
  classOverride?: string;
  children: React.ReactNode;
}
export const DialogButtonGroup: React.FC<DialogButtonGroupProps> = ({
  className,
  classOverride,
  children,
}) => {
  const classNames =
    classOverride ??
    classnames(
      "adiago-dialog-button-group pt-8 flex justify-end space-x-4",
      className,
    );

  return <div className={classNames}>{children}</div>;
};

DialogButtonGroup.displayName = "DialogButtonGroup";
