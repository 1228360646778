import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { SectionComponent } from "./section-component";

export const SectionNode = Node.create({
  name: "section",
  group: "block",
  // content: "sectionHeader line+",
  content: "sectionContent+",
  atom: true,
  draggable: true,
  parseHTML() {
    return [
      {
        tag: "section",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["section", mergeAttributes(HTMLAttributes), 0];
  },
  addNodeView() {
    return ReactNodeViewRenderer(SectionComponent);
  },
});
