import { Button, Popover, copyToClipboard } from "@songleaf/ui";
import { Link, Share } from "lucide-react";
import { CollaboratorList } from "./collaborator-list";
import { Invite } from "./invite";

interface SongShareProps {}

export const SongShare: React.FC<SongShareProps> = () => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          size="sm"
          icon={<Share size={16} />}
          color="opaque"
          variant="transparent"
        />
      </Popover.Trigger>
      <Popover.Content
        title="Share"
        align="end"
        sideOffset={-32}
        alignOffset={4}
      >
        <div className="flex min-w-[280px] flex-col items-center justify-between pt-2 lg:min-w-[468px]">
          <Invite />

          <CollaboratorList />

          {/* seperator */}
          <div className="mt-4 w-full border-b border-surface-level-2" />

          <div className="flex w-full items-center justify-end pt-2">
            {/* <Input
              value={window.location.href}
              disabled
              containerClassName="flex-1 pr-1"
            /> */}
            <Button
              size="xs"
              color="opaque"
              variant="transparent"
              className="py-2 px-3"
              onClick={() => copyToClipboard(window.location.href)}
            >
              <Link size={8} className="pr-1" />
              Copy link
            </Button>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};
