import { AudioRecorder } from "./audio-recorder";
import { AudioPlayerSimple } from "./audio-player-simple";
import { AudioPlayerTiny } from "./audio-player-tiny";
import { AudioRecorderOmni } from "./audio-recorder-omni";
import { AudioPlayerOmni } from "./audio-player-omni";
import { AudioRecorderMicSelect } from "./audio-recorder-mic-select";

export { useWavesurfer, useWavesurferRecorder } from "./use-wavesurfer";

export const Audio = {
  Recorder: AudioRecorder,
  OmniRecorder: AudioRecorderOmni,
  Player: AudioPlayerSimple,
  OmniPlayer: AudioPlayerOmni,
  PlayerTiny: AudioPlayerTiny,
  MicSelect: AudioRecorderMicSelect,
};
