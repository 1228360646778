import { get, set, update, del, clear, createStore } from "idb-keyval";

const slStore = createStore("sl-cache", "sl-cache-store");

export const KVBase = {
  get: async (key: string) => {
    return get(key, slStore);
  },
  set: async (key: string, value: any) => {
    return set(key, value, slStore);
  },
  update: async (key: string, value: any) => {
    return update(key, (prev) => ({ ...prev, ...value }), slStore);
  },
  del: async (key: string) => {
    return del(key, slStore);
  },
  clear: async () => {
    return clear(slStore);
  },
};
