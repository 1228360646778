import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { HocuspocusProviderWebsocket } from "@hocuspocus/provider";

const CollabSocketContext = createContext<HocuspocusProviderWebsocket | null>(
  null,
);

export const useCollabSocket = () => useContext(CollabSocketContext);

export const CollabSocketProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const socket = useMemo(() => {
    if (!import.meta.env.VITE_HP_URL) {
      throw new Error("VITE_HP_URL not set");
    }

    return new HocuspocusProviderWebsocket({
      url: import.meta.env.VITE_HP_URL,
      initialDelay: 1000,
      delay: 5000,
      maxAttempts: 5,
    });
  }, []);

  return (
    <CollabSocketContext.Provider value={socket}>
      {children}
    </CollabSocketContext.Provider>
  );
};
