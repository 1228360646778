import { Button } from "@songleaf/ui";
import { PlusIcon } from "@heroicons/react/24/solid";
import { HomeSidebarSongListitem } from "./home-sidebar-song-listitem";
import { useAllSongs, useCreateSong } from "../../../api/songs";
import { HomeSidebarHeader } from "./home-sidebar-header";

export const HomeSidebarSongList = () => {
  const { data: songs } = useAllSongs();
  const { mutate: createSong, isLoading: createSongLoading } = useCreateSong();

  return (
    <>
      <HomeSidebarHeader />

      <div className="h-2" />

      <h1 className="cursor-pointer select-none text-lg font-bold">Songs</h1>
      <div className="h-1" />

      {songs?.map((s) => (
        <HomeSidebarSongListitem key={s.id} song={s} />
      ))}

      <div className="h-20" />

      <Button
        shape="circle"
        size="md"
        className="absolute left-1/2 bottom-4 -translate-x-1/2"
        icon={<PlusIcon className="h-7 w-7" />}
        loading={createSongLoading}
        onClick={() => createSong({ title: "Untitled" })}
      />
    </>
  );
};
