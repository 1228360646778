import {
  createTRPCProxyClient,
  createTRPCReact,
  httpBatchLink,
} from "@trpc/react-query";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import type { AppRouter } from "@songleaf/api";
import { transformer } from "@songleaf/api/transformer";
import { config } from "../_config";

export type TRPCInputs = inferRouterInputs<AppRouter>;
export type TRPCOutputs = inferRouterOutputs<AppRouter>;

export const trpc = createTRPCReact<AppRouter>();

export const createTrpc = (token: string) =>
  createTRPCProxyClient<AppRouter>({
    transformer,
    links: [
      httpBatchLink({
        url: `${config.api.url}/api/v1/trpc`,
        // You can pass any HTTP headers you wish here
        async headers() {
          try {
            return {
              authorization: `Bearer ${token}`,
            };
          } catch {
            return {};
          }
        },
      }),
    ],
  });
