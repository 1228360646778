import { NodeViewProps } from "@tiptap/core";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import { Copy, Trash2 } from "lucide-react";

import { useCurrentSong } from "@/api/songs";

import { NodeMenu } from "../node-menu";
import { canEditSong } from "@/_utils/permissions";

export const LineComponent: React.FC<NodeViewProps> = ({
  editor,
  node,
  deleteNode,
  getPos,
}) => {
  const { role } = useCurrentSong();
  // keeping this around for future reference
  // const intoTitle = () => {
  //   const pos = getPos();
  //   return editor
  //     .chain()
  //     .deleteRange({ from: pos, to: pos + node.nodeSize })
  //     .insertContentAt(pos, {
  //       type: "sectionHeader",
  //       content: node.content.toJSON(),
  //     })
  //     .focus()
  //     .run();
  // };
  const createLine = () => {
    return editor
      .chain()
      .insertContentAt(getPos() + node.nodeSize, {
        type: node.type.name,
      })
      .focus()
      .run();
  };
  const copyLine = () => {
    return editor
      .chain()
      .insertContentAt(getPos() + node.nodeSize, {
        type: node.type.name,
        content: node.content.toJSON(),
      })
      .focus()
      .run();
  };

  return (
    <NodeViewWrapper className="line-node">
      <div className="line group/line">
        <NodeViewContent className="line-content" />
        {canEditSong(role) ? (
          <NodeMenu
            parentType="line"
            plusAction={() => createLine()}
            dropdownItems={[
              {
                key: `${node.attrs.id}-duplicate`,
                type: "basic",
                label: "Duplicate",
                onClick: () => copyLine(),
                icon: <Copy size={12} />,
              },
              {
                key: `${node.attrs.id}-delete`,
                type: "basic",
                label: "Delete",
                color: "danger",
                onClick: () => deleteNode(),
                icon: <Trash2 size={12} />,
              },
            ]}
          />
        ) : null}
      </div>
    </NodeViewWrapper>
  );
};
