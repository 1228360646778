import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export type ToolbarStates = "closed" | "recorder" | "chords";

interface AudioState {
  preferredMic?: MediaDeviceInfo["deviceId"];
  setPreferredMic: (deviceId?: string) => void;
}

export const useAudioStore = create<AudioState>()(
  devtools(
    persist(
      (set) => ({
        preferredMic: undefined,
        setPreferredMic: (deviceId) => set({ preferredMic: deviceId }),
      }),
      {
        name: "audio-state-storage",
      },
    ),
  ),
);
