import { NodeViewProps } from "@tiptap/core";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";

export const SectionComponent: React.FC<NodeViewProps> = (
  {
    // editor,
    // node,
  },
) => {
  // const createSection = () => {
  //   return editor
  //     .chain()
  //     .insertContentAt(editor.state.selection.head, {
  //       type: node.type.name,
  //       content: [
  //         {
  //           type: "sectionHeader",
  //           content: [{ type: "text", text: "Verse 1" }],
  //         },
  //         {
  //           type: "line",
  //         },
  //       ],
  //     })
  //     .focus()
  //     .run();
  // };

  return (
    <NodeViewWrapper className="section-node">
      <div className="section">
        <div className="section-inner">
          <NodeViewContent className="section-content" />
          {/* <SectionAudio /> */}
        </div>
        {/* <NodeMenu
          parentType="section"
          plusAction={() => createSection()}
          dropdownItems={[
            {
              key: `${node.attrs.id}-delete`,
              type: "basic",
              label: "Delete",
              color: "danger",
              onClick: () => deleteNode(),
              icon: <Trash2 size={12} />,
            },
          ]}
        /> */}
      </div>
    </NodeViewWrapper>
  );
};
