import { useState } from "react";
import { Button, Input } from "@songleaf/ui";
import { useCurrentSong } from "../../../api/songs";
import { useCreateSongInvite } from "../../../api/songInvites";
import { useCurrentUser } from "../../../api/users";

export const Invite: React.FC = () => {
  const { data: user } = useCurrentUser();
  const { song, role } = useCurrentSong();

  const [emailInput, setEmailInput] = useState("");

  const { mutate: createInvite, isLoading } = useCreateSongInvite(
    song?.id || "",
    {
      onSuccess: () => {
        setEmailInput("");
      },
    },
  );

  if (!song || role !== "owner") return null;

  return (
    <div className="flex w-full items-center justify-between pt-2">
      <Input
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
        placeholder="Enter email address"
        containerClassName="flex-1 pr-1"
      />
      {/* make this a dropdown combo button to choose role */}
      <Button
        size="xs"
        color="primary"
        variant="standard"
        loading={isLoading}
        className="py-2 px-3"
        disabled={!emailInput || user?.email === emailInput}
        onClick={() =>
          createInvite({
            songId: song?.id || "",
            toEmail: emailInput,
            role: "viewer",
          })
        }
      >
        Invite
      </Button>
    </div>
  );
};
