import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js";

import { useWavesurfer } from "./use-wavesurfer";
import { formatTime } from "../_utils/functions";
import { Loader } from "../loader";

export interface AudioPlayerTinyProps {
  className?: string;
  classOverride?: string;
  url?: string;
  loading?: boolean;
}

const initialOptions: Omit<WaveSurferOptions, "container"> = {
  height: 16,
  waveColor: "#2D342F",
  progressColor: "#36EA73",
  cursorColor: "#36EA73",
  cursorWidth: 2,
  barWidth: 1.25,
  barGap: 2,
  barRadius: 2,
  barHeight: 0.98,
  normalize: false,
  minPxPerSec: 1,
  fillParent: true,
  // url: "/wavesurfer-code/examples/audio/audio.wav",
  mediaControls: false,
  audioRate: 1,
  autoScroll: false,
  autoCenter: true,
};

export const AudioPlayerTiny: React.FC<AudioPlayerTinyProps> = ({
  className,
  url,
  loading,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useWavesurfer(containerRef, { ...initialOptions, url });

  const [isPlaying, setIsPlaying] = useState(false);
  const [ready, setReady] = useState(false);

  // On play button click
  const onTogglePlaying = useCallback(() => {
    wavesurfer?.isPlaying() ? wavesurfer.pause() : wavesurfer?.play();
  }, [wavesurfer]);

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!wavesurfer) return;

    setIsPlaying(false);

    const subscriptions = [
      wavesurfer.on("play", () => setIsPlaying(true)),
      wavesurfer.on("pause", () => setIsPlaying(false)),
      wavesurfer.on("ready", () => setReady(true)),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  const classNames = classnames(
    "adiago-audio-tiny peer/audio-tiny-player",
    "py-1.5 px-1.5 bg-surface-base rounded-lg transition-colors bg-surface-base hover:bg-surface-level-2 focus-within:bg-surface-hover",
    className,
  );

  return (
    <div className={classNames}>
      <div className="flex items-center space-x-2">
        <button
          className="min-w-4 flex h-4 w-4 items-center justify-center text-text-primary-3 transition-colors hover:text-text-primary-2"
          onClick={onTogglePlaying}
          disabled={(wavesurfer && !ready) || loading}
        >
          {(wavesurfer && !ready) || loading ? (
            <Loader className="h-3 w-3 fill-text-primary-3" />
          ) : isPlaying ? (
            <PauseIcon className="h-4 w-4" />
          ) : (
            <PlayIcon className="h-4 w-4" />
          )}
        </button>
        {url ? (
          <div
            className="min-h-4 w-full min-w-[200px] flex-shrink-[2] rounded-lg bg-transparent"
            ref={containerRef}
          />
        ) : (
          <div className="w-full flex-shrink-[2]" />
        )}
        <PlayerTime wavesurfer={wavesurfer} />
      </div>
    </div>
  );
};

interface PlayerTimeProps {
  wavesurfer: WaveSurfer | null;
}
const PlayerTime: React.FC<PlayerTimeProps> = ({ wavesurfer }) => {
  const [currentTime, setCurrentTime] = useState(0);
  useEffect(() => {
    if (!wavesurfer) return;

    const subscriptions = [
      wavesurfer.on("timeupdate", (ct) => setCurrentTime(ct * 1000)),
      wavesurfer.on("ready", () => {
        setCurrentTime(wavesurfer.getDuration() * 1000);
      }),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  return (
    <p className="w-full max-w-[32px] text-xs font-medium text-text-secondary">
      {formatTime(currentTime)}
    </p>
  );
};
