import React, { useCallback, useEffect, useRef, useState } from "react";
import RecordPlugin, {
  RecordPluginOptions,
} from "wavesurfer-record-plugin-wav";
import { StopIcon } from "@heroicons/react/24/solid";
import { Mic } from "lucide-react";

import { BlobEncoding, OnRecordEndFn, MediaDeviceInfo } from "./types";
import { useWavesurfer, useWavesurferRecorder } from "./use-wavesurfer";
import { Loader } from "../loader";
import Select from "../select";
import { Button } from "../button";

export interface AudioRecorderProps {
  blobEncoding?: BlobEncoding;
  onRecordStart?: () => void;
  onRecordEnd?: OnRecordEndFn;
  loading?: boolean;
  recordOnMount?: boolean;
  preferredDeviceId?: string;
}

const initialOptions = {
  height: 42,
  waveColor: "#fecdd3",
  progressColor: "#881337",
  cursorColor: "#e11d48",
  cursorWidth: 3,
  barWidth: 2,
  barGap: 2,
  barRadius: 30,
  barHeight: 0.96,
  minPxPerSec: 0.01,
  fillParent: true,
  // url: "/wavesurfer-code/examples/audio/audio.wav",
  mediaControls: false,
  audioRate: 1,
  autoScroll: false,
  autoCenter: false,
};

const initialRecorderOptions: RecordPluginOptions = {
  renderRecordedAudio: false,
  scrollingWaveform: true,
};

export const AudioRecorder: React.FC<AudioRecorderProps> = ({
  blobEncoding,
  onRecordStart,
  onRecordEnd,
  loading,
  recordOnMount,
  preferredDeviceId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useWavesurfer(containerRef, initialOptions);
  const { recorder, isRecording } = useWavesurferRecorder(
    wavesurfer,
    { ...initialRecorderOptions, mimeType: blobEncoding },
    {
      onRecordStart,
      onRecordEnd,
    },
  );

  const [mounted, setMounted] = useState(false);

  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<string>();
  useEffect(() => {
    if (preferredDeviceId) {
      setSelectedDevice(preferredDeviceId);
      return;
    }
    RecordPlugin.getAvailableAudioDevices().then(
      (devices: MediaDeviceInfo[]) => {
        setDevices(devices);
        setSelectedDevice(devices[0]?.deviceId);
        recordOnMount &&
          recorder?.startRecording({ deviceId: preferredDeviceId });
      },
    );
  }, []);

  useEffect(() => {
    if (!mounted && recordOnMount && recorder) {
      recordOnMount &&
        recorder?.startRecording({ deviceId: preferredDeviceId });
      setMounted(true);
    }
  }, [recorder, preferredDeviceId]);

  const handleRecordPress = useCallback(() => {
    if (!recorder) return;

    if (isRecording) {
      recorder.stopRecording();
      recorder.stopMic();
      return;
    }
    recorder.startRecording({ deviceId: selectedDevice });
  }, [recorder, isRecording, selectedDevice]);

  return (
    <div className="flex w-full flex-col space-y-2 rounded-lg bg-surface-base p-2">
      <div className="flex justify-between">
        <Select.Root
          value={selectedDevice}
          onValueChange={setSelectedDevice}
          disabled={isRecording || loading}
        >
          <Select.Trigger
            placeholder="Select a mic"
            classOverride="adiago-select-trigger px-2 py-1 flex items-center justify-between space-x-2 min-w-[4rem] text-xs text-text-primary-3 rounded focus-visible:outline-surface-level-2"
            iconClassNameOverride="adiago-select-icon w-4 text-text-primary-2"
            leftIcon={<Mic size={12} />}
          />

          <Select.Content>
            <Select.Viewport>
              {devices.map((device) => (
                <Select.Item
                  value={device.deviceId}
                  key={`recorder-mic-${device.deviceId}`}
                  title={device.label}
                />
              ))}
            </Select.Viewport>
          </Select.Content>
        </Select.Root>

        <Button
          size="xs"
          variant="transparent"
          color="opaque"
          onClick={() => onRecordEnd?.()}
        >
          Cancel
        </Button>
      </div>
      <div className="flex items-center space-x-2">
        <RecordButton
          isRecording={isRecording}
          onClick={handleRecordPress}
          isLoading={loading}
        />
        <div
          className="w-full min-w-[200px] rounded-lg bg-rose-50"
          ref={containerRef}
        />
      </div>
    </div>
  );
};

interface RecordButtonProps {
  isRecording?: boolean;
  isLoading?: boolean;
  onClick: () => void;
}
export const RecordButton: React.FC<RecordButtonProps> = ({
  isRecording,
  isLoading,
  onClick,
}) => {
  if (isLoading) {
    return (
      <button
        className="flex h-6 w-6 items-center justify-center rounded-full bg-rose-200 text-surface-base"
        disabled
      >
        <Loader className="fill-surface-base" />
      </button>
    );
  }
  if (isRecording) {
    return (
      <button
        className="flex h-6 w-6 items-center justify-center rounded-full bg-rose-500 text-surface-base"
        onClick={onClick}
      >
        <StopIcon width={13} />
      </button>
    );
  }

  return (
    <button
      className="flex h-6 w-6 items-center justify-center rounded-full bg-surface-level-2"
      onClick={onClick}
    >
      <div className="flex h-[22px] w-[22px] items-center justify-center rounded-full bg-surface-level-1">
        <div className="h-4 w-4 rounded-full bg-rose-500" />
      </div>
    </button>
  );
};
