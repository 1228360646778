console.log(
  `%cSongleaf ${__APP_VERSION__}`,
  "background: #36EA73; color: white; border-radius: 4px; padding: 4px 6px; font: Epilogue, sans-serif;",
);

export const VersionPill = () => {
  return (
    <span className="text-text-primary-2 fixed bottom-3 right-3 rounded-full bg-surface-level-2 px-3 py-1 text-xs font-medium">
      {__APP_VERSION__}
    </span>
  );
};
