import React, { PropsWithChildren } from "react";
import { useAuth } from "@clerk/clerk-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createWSClient, httpBatchLink, splitLink, wsLink } from "@trpc/client";
import { transformer } from "@songleaf/api/transformer";

import { trpc } from "../../_utils/trpc";
import { config } from "../../_config";
import { queryClient } from "../../_utils/query-client";

const url = `${config.api.url.replace("http", "ws")}/api/v1/trpc`;

/**
 * Requires ClerkProvider
 */
export const ApiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { getToken } = useAuth();
  const [token, setToken] = React.useState<string>();

  React.useEffect(() => {
    (async () => {
      if (!getToken) return;
      const authToken = await getToken();
      if (!authToken) return;
      const subToken = await getSubscriptionToken(authToken);
      setToken(subToken);
    })();
  }, [getToken]);

  const trpcClient = React.useMemo(() => {
    if (!token) return;

    const wsClient = createWSClient({
      url: `${url}?token=${token}`,
      retryDelayMs: () => 3000,
    });

    return trpc.createClient({
      transformer,
      links: [
        splitLink({
          condition(op) {
            return op.type === "subscription";
          },
          true: wsLink({
            client: wsClient,
          }),
          false: httpBatchLink({
            url: `${config.api.url}/api/v1/trpc`,
            // You can pass any HTTP headers you wish here
            async headers() {
              try {
                const token = await getToken();
                if (!token) return {};
                return {
                  authorization: `Bearer ${token}`,
                };
              } catch {
                return {};
              }
            },
          }),
        }),
      ],
    });
  }, [token]);

  if (!trpcClient) {
    return null;
  }

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </trpc.Provider>
  );
};

async function getSubscriptionToken(authToken: string) {
  const res = await fetch(`${config.api.url}/api/auth/subscription-token`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  const { token } = await res.json();
  return token;
}
