import { Button } from "@songleaf/ui";
import React from "react";
import { trpc } from "../../../_utils/trpc";

export const TestAccceptInviteNotification: React.FC = () => {
  const { mutate: testAccceptInviteNotification, isLoading } =
    trpc.theMatrix.testAccceptInviteNotification.useMutation({
      async onSuccess() {
        console.log("notification triggered");
      },
    });

  return (
    <Button onClick={() => testAccceptInviteNotification()} loading={isLoading}>
      test accept invite notification
    </Button>
  );
};
