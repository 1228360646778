import { Key, getScaleNotes } from "@/_utils/chords";
import { useMemo } from "react";

interface DraggableChordsProps {
  activeKey: Key;
}

export const DraggableChords: React.FC<DraggableChordsProps> = ({
  activeKey,
}) => {
  const chords = useMemo(() => {
    const notes = getScaleNotes(activeKey).slice(0, -1);
    return notes.map((note) => {
      return <ChordDraggable key={`drag-chord-${note}`} chord={note} />;
    });
  }, [activeKey]);

  return <div className="flex justify-between space-x-1 p-2">{chords}</div>;
};

export const ChordDraggable = ({ chord }: { chord: string }) => {
  const onDragStart = (ev: any) => {
    ev.dataTransfer.setData("text/html", `[${chord}]`);
  };
  return (
    <div
      className="flex flex-1 cursor-grab items-center justify-center space-x-1 rounded-md px-2.5 py-1 text-xs font-semibold text-text-primary-1 transition-colors hover:bg-surface-level-2"
      draggable
      onDragStart={onDragStart}
    >
      <p>{chord}</p>
    </div>
  );
};
