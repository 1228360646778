import RecordPlugin from "wavesurfer-record-plugin-wav";
import { useEffect, useState } from "react";
import classnames from "classnames";
import * as RadixSelect from "@radix-ui/react-select";
import Select from "../select";
import { ChevronDownIcon, Mic } from "lucide-react";

export interface AudioRecorderMicSelectProps {
  deviceId?: string;
  setDeviceId: (deviceId?: string) => void;
  shouldRefetch?: boolean;
}

export const AudioRecorderMicSelect: React.FC<AudioRecorderMicSelectProps> = ({
  deviceId,
  setDeviceId,
  shouldRefetch,
}) => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    if (shouldRefetch) {
      (async () => {
        let devices = await RecordPlugin.getAvailableAudioDevices();
        if (devices.length === 1 && devices[0]?.deviceId === "") {
          const hasPermission = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          if (!hasPermission) return;
          devices = await RecordPlugin.getAvailableAudioDevices();
        }
        const d = devices.filter((d) => !d.label.includes("Default - "));
        setDevices(d);
        if (!deviceId || !d.some((d) => d.deviceId === deviceId)) {
          setDeviceId(d[0]?.deviceId);
        }
      })();
    }

    navigator.mediaDevices.ondevicechange = () => {
      RecordPlugin.getAvailableAudioDevices().then((devices) => {
        const mainDevices = devices.filter(
          (d) => !d.label.includes("Default - "),
        );
        setDevices(mainDevices);
        // if the current device is not in the list, set it to the first one
        if (!mainDevices.some((d) => d.deviceId === deviceId))
          setDeviceId(mainDevices[0]?.deviceId);
      });
    };

    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, [shouldRefetch]);

  return (
    <Select.Root value={deviceId} onValueChange={setDeviceId}>
      <SelectTrigger
        label={devices.find((d) => d.deviceId === deviceId)?.label}
      />

      <Select.Content sideOffset={8} position="popper" align="center">
        <Select.Viewport>
          {devices.map((device) => (
            <SelectItem
              key={device.deviceId}
              value={device.deviceId}
              title={device.label}
            />
          ))}
        </Select.Viewport>
      </Select.Content>
    </Select.Root>
  );
};

const SelectTrigger = ({ label }: { label?: string }) => {
  return (
    <RadixSelect.Trigger className="songleaf-mic-select-trigger flex w-40 items-center space-x-2 rounded-md bg-surface-base px-1.5 py-1 text-xs font-semibold transition-colors hover:bg-surface-level-2 focus-visible:outline-none rx-state-open:bg-surface-level-2">
      <Mic size={12} />
      <RadixSelect.Value placeholder="Select a mic" asChild>
        <span className="text-nowrap text-start flex-1 overflow-hidden overflow-ellipsis">
          {label || "Select a mic"}
        </span>
      </RadixSelect.Value>
      <RadixSelect.SelectIcon>
        <ChevronDownIcon size={12} />
      </RadixSelect.SelectIcon>
    </RadixSelect.Trigger>
  );
};

interface SelectItemProps {
  value: string;
  title: string;
}
const SelectItem: React.FC<SelectItemProps> = ({ value, title }) => {
  const classes = classnames(
    `adiago-select-item py-1 px-2 flex w-full items-center justify-between space-x-1.5 relative text-sm rounded-md`,
    `transition duration-75 text-text-primary cursor-pointer focus:outline-none`,
    `hover:bg-surface-level-2 rx-disabled:text-text-primary-2 rx-disabled:pointer-events-none`,
  );
  return (
    <RadixSelect.Item value={value} className={classes}>
      <RadixSelect.ItemText>{title}</RadixSelect.ItemText>
      <RadixSelect.ItemIndicator className="h-1.5 w-1.5 rounded-full bg-leaf-500"></RadixSelect.ItemIndicator>
    </RadixSelect.Item>
  );
};
