import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { InlineAudioComponent } from "./inline-audio-component";

export const InlineAudioNode = Node.create({
  name: "inline-audio",
  group: "sectionContent",
  // content: "inline*",
  atom: true,
  draggable: true,
  parseHTML() {
    return [
      {
        tag: "inline-audio",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["inline-audio", mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(InlineAudioComponent);
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          return {
            "data-id": attributes.id,
          };
        },
      },
      new: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-new"),
        renderHTML: (attributes) => {
          return {
            "data-new": attributes.new ? true : null,
          };
        },
      },
    };
  },
});
