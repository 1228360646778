import React, { useEffect } from "react";
import classnames from "classnames";
import { useMediaQuery } from "../_hooks";

export type TSidebarPosition = "left" | "right";

export interface SidebarRootProps {
  children?: React.ReactNode;
  position?: TSidebarPosition;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  showToggle?: boolean;
}

const baseClasses =
  "adiago-sidebar-root z-10 h-full border-border-2 bg-surface-base transition-[width] duration-75 ease-in-out overflow-hidden shadow-lg absolute top-0 left-0 lg:relative lg:shadow-none";
const positionClasses = {
  left: "border-r",
  right: "border-l",
};
const openClasses = {
  true: {
    left: "w-[320px] translate-x-0",
    right: "w-[320px] -translate-x-0",
  },
  false: {
    left: "w-0 -translate-x-full",
    right: "w-0 translate-x-full",
  },
};

const toggleOpenClasses = {
  true: {
    left: "translate-x-2",
    right: "-translate-x-0",
  },
  false: {
    left: "translate-x-2",
    right: "translate-x-full",
  },
};

export const SidebarRoot: React.FC<SidebarRootProps> = ({
  children,
  position = "left",
  isOpen = true,
  setIsOpen,
  showToggle = true,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 1024px)");
  const [open, setOpen] = React.useState(true);
  const classNames = classnames(
    baseClasses,
    positionClasses[position],
    openClasses[`${open}`][position],
  );
  const toggleClassnames = classnames(
    "absolute top-3 left-2 transform cursor-pointer rounded-md p-1 hover:bg-surface-level-2 z-0 text-text-secondary transition-colors hover:bg-surface-level-2 hover:text-text-primary",
    toggleOpenClasses[`${open}`][position],
  );

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div className="relative z-40 h-full">
      {/* invisible background for clicking off sidebar when open on mobile */}
      {isSmallScreen && isOpen && (
        <div
          className="absolute top-0 left-0 h-full w-screen"
          onClick={() => (setIsOpen ? setIsOpen(false) : setOpen((_) => false))}
        />
      )}
      {/* content */}
      <div className={classNames}>{children}</div>
      {/* toggle button */}
      {showToggle && (
        <button
          className={toggleClassnames}
          onClick={() => (setIsOpen ? setIsOpen(!isOpen) : setOpen((o) => !o))}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-4 w-4"
          >
            <path d="M9 18l6-6-6-6"></path>
          </svg>
        </button>
      )}
    </div>
  );
};

SidebarRoot.displayName = "SidebarRoot";
