import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type ToolbarStates = "closed" | "recorder" | "chords";

interface EditorToolbarState {
  state: ToolbarStates;
  setState: (state: ToolbarStates) => void;
  temporaryAudioId?: string;
  setTemporaryAudioId: (id?: string) => void;
}

export const useEditorToolbarStore = create<EditorToolbarState>()(
  devtools((set) => ({
    state: "closed",
    setState: (state) => set({ state }),
    temporaryAudioId: undefined,
    setTemporaryAudioId: (temporaryAudioId) => set({ temporaryAudioId }),
  })),
);
