import { KVBase } from "./kv-base";

import { getAudioFileBlob, uploadAudioFile } from "../../api/audio";

/** [songId, audioId] */
export type KVAudioKey = [string, string];

export interface KVAudioValue {
  blob: Blob;
  songId: string;
  uploadPending?: boolean;
  uploadLock?: boolean;
}
export interface KVAudioReturn {
  blob: Blob;
  url: string;
}

export const KVAudio = {
  get: async ([songId, key]: KVAudioKey): Promise<
    KVAudioReturn | undefined
  > => {
    const value: KVAudioValue | undefined = await KVBase.get(key);
    if (!value) {
      const blob = await getAudio(songId, key);
      return createKVAudioReturn(blob);
    }

    return createKVAudioReturn(value.blob);
  },
  set: async ([songId, key]: KVAudioKey, value: Blob) => {
    return KVBase.set(key, { blob: value, songId });
  },
  update: async (key: string, value: KVAudioValue) => {
    return KVBase.update(key, value);
  },
  del: async (key: string, ignoreLock = false) => {
    const value: KVAudioValue | undefined = await KVBase.get(key);
    if (!value) return;
    if (value.uploadLock === true && ignoreLock) return;

    return KVBase.del(key);
  },
};

const createKVAudioReturn = async (blob?: Blob) => {
  if (!blob) return;
  const url = URL.createObjectURL(blob);
  return { blob, url };
};

const getAudio = async (songId: string, key: string) => {
  try {
    const audioFileBlob = await getAudioFileBlob(key);
    await KVAudio.set([songId, key], audioFileBlob);
    return audioFileBlob;
  } catch (err) {
    return;
  }
};

export const uploadCachedAudio = async (songId: string, key: string) => {
  const value: KVAudioValue | undefined = await KVBase.get(key);
  if (!value) return;

  try {
    // lock the record, queue the upload
    await KVBase.set(key, {
      blob: value.blob,
      uploadLock: true,
      uploadPending: true,
    });

    await uploadAudioFile(songId, { blob: value.blob, uuid: key });

    // on success, update the cache
    await KVBase.set(key, { blob: value.blob, songId });
  } catch (err) {
    // on failure, unlock the record
    await KVBase.set(key, {
      blob: value.blob,
      songId,
      uploadLock: false,
      uploadPending: true,
    });
  }
};
