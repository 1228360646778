import { useMemo } from "react";
import { Sidebar } from "@songleaf/ui";
import { useSidebarStore } from "@/state/sidebar";
import { HomeSidebarSongList } from "./home-sidebar-song-list";
import { HomeSidebarNotifications } from "./home-sidebar-notifications";

export const HomeSidebar = () => {
  const {
    isOpen: isSidebarOpen,
    toggleOpen: toggleSidebar,
    screen,
  } = useSidebarStore();

  const currentScreen = useMemo(() => {
    switch (screen) {
      case "song-list":
        return <HomeSidebarSongList />;
      case "notifications":
        return <HomeSidebarNotifications />;
      default:
        return null;
    }
  }, [screen]);

  return (
    <Sidebar.Root
      isOpen={isSidebarOpen}
      setIsOpen={toggleSidebar}
      // showToggle={!isSidebarOpen}
    >
      <Sidebar.Content className="h-full px-4">{currentScreen}</Sidebar.Content>
    </Sidebar.Root>
  );
};
