import { createTrpc } from "../_utils/trpc";
import { getClerkToken } from "../_utils/auth";

export interface UseUploadImageFileOptions {
  songId?: string;
  blob?: Blob;
  uuid?: string;
  cropDimensions?: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
}

// export const useUpdateImageFile = (imageFileId: string) => {
//   return trpc.image.updateImageFile.useMutation({
//     onMutate({ cropDimensions }) {
//       Cache.Image.update(imageFileId, { cropDimensions });
//     },
//   });
// };

export const getImageFile = async (imageFileId: string) => {
  const token = await getClerkToken();

  const $trpc = createTrpc(token);

  return $trpc.image.getImageFile.query(imageFileId);
};

export const getImageFileUrl = async (imageFileId: string, variant: string) => {
  const token = await getClerkToken();
  const $trpc = createTrpc(token);

  return $trpc.image.getImageFileGetUrl.query({ imageFileId, variant });
};

export const getImageFileBlob = async (
  imageFileId: string,
  variant: string,
) => {
  const imageFileUrlResponse = await getImageFileUrl(imageFileId, variant);

  const response = await fetch(imageFileUrlResponse.url);
  return response.blob();
};

export const uploadImageFile = async ({
  blob,
  uuid = crypto.randomUUID(),
  cropDimensions,
}: UseUploadImageFileOptions) => {
  if (!blob) {
    return;
  }

  try {
    const token = await getClerkToken();

    const $trpc = createTrpc(token);

    // create image file
    const { id: imageFileId } = await $trpc.image.createImageFile.mutate({
      uuid,
      cropDimensions,
    });

    const { url, potentialStorageId } =
      await $trpc.image.getImageFileUploadUrl.query(imageFileId);

    const formData = new FormData();
    formData.append("file", blob);

    // upload image file
    await fetch(url, {
      method: "POST",
      body: formData,
    });

    // set image storage id
    const imageFile = await $trpc.image.updateImageFile.mutate({
      imageFileId,
      storageId: potentialStorageId,
    });

    return imageFile;
  } catch (error) {
    console.error("uploadImageFile: ", error);
  }
};

export const isImageFileUploaded = async (uuid: string) => {
  const imageFile = await getImageFile(uuid);

  // missing storage id means the file is not uploaded to cloudflare but
  // we may not need to use that as a check
  if (!imageFile || !imageFile.storageId) {
    return false;
  }

  return true;
};
