import { trpc } from "../_utils/trpc";

interface UseCreateSongInvite {
  onSuccess?: (invite: any) => void;
}
export const useCreateSongInvite = (
  songId: string,
  { onSuccess }: UseCreateSongInvite,
) => {
  const tutils = trpc.useContext();
  return trpc.songInvite.create.useMutation({
    onSuccess: (invite) => {
      onSuccess?.(invite);
      // update list of invites
      tutils.song.collborators.invalidate(songId);
    },
  });
};

export const useCollaborators = (songId = "") => {
  return trpc.song.collborators.useQuery(songId, { enabled: !!songId });
};

export const useUpdateCollaborator = (songId = "") => {
  const tutils = trpc.useContext();
  return trpc.song.updateCollaborator.useMutation({
    onSuccess: () => {
      // update list of collaborators
      tutils.song.collborators.invalidate(songId);
    },
  });
};

export const useRemoveCollaborator = (songId = "") => {
  const tutils = trpc.useContext();
  return trpc.song.removeCollaborator.useMutation({
    onSuccess: () => {
      // update list of collaborators
      tutils.song.collborators.invalidate(songId);
    },
  });
};

export const useUpdateInvite = (songId = "") => {
  const tutils = trpc.useContext();
  return trpc.songInvite.updateRole.useMutation({
    onSuccess: () => {
      // update list of invites
      tutils.song.collborators.invalidate(songId);
    },
  });
};

export const useRemoveInvite = (songId = "") => {
  const tutils = trpc.useContext();
  return trpc.songInvite.removeInvite.useMutation({
    onSuccess: () => {
      // update list of invites
      tutils.song.collborators.invalidate(songId);
    },
  });
};
