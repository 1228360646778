import { useCallback, useState } from "react";
import { Cache } from "@/_utils/cache/cache";
import { Input, Dialog as SLDialog } from "@songleaf/ui";
import { ArtworkImage } from "@/components/artwork-image";
import { useCurrentSong, useUpdateSong } from "@/api/songs";
import cn from "classnames";
import { canEditSong } from "@/_utils/permissions";

export const ArtworkDialog: React.FC = () => {
  const { song, role } = useCurrentSong();
  const { mutate: updateSong, isLoading } = useUpdateSong(song?.id);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (song && file) {
        setIsUploading(true);
        const reader = new FileReader();
        reader.onload = async (ev) => {
          if (!ev.target?.result || typeof ev.target.result === "string")
            return;

          const uuid = crypto.randomUUID();
          const blob = new Blob([new Uint8Array(ev.target.result)], {
            type: file.type,
          });

          // @TODO: this could work if got around the song update on the backend requiring the artworkId
          // could do something with uploading image in background - once uploaded update the song
          // in the mean time could set the artworkId to the uuid on the frontend
          await Cache.Image.set(uuid, blob, { songId: song.id });

          updateSong({
            songId: song.id,
            artworkId: uuid,
          });
          setIsUploading(false);
        };
        reader.readAsArrayBuffer(file);
      }
    },
    [song],
  );

  return (
    <SLDialog.Root>
      <SLDialog.Trigger asChild>
        <button className="relative z-10 h-12 w-12 rounded-lg">
          <div className="group/artwork absolute top-0 origin-top-left rounded-lg shadow-none transition-all hover:scale-110 hover:shadow-lg">
            <ArtworkImage
              imageId={song?.artworkId}
              size="32x32"
              className="h-12 w-12 rounded-lg"
            />
            <img
              className="group-hover/artwork:opacity-70 group-hover/artwork:delay-100 delay-0 absolute right-1 bottom-1 opacity-0 drop-shadow-djent transition-all"
              src="/assets/icons/arrow-bottom-right.svg"
            />
          </div>
        </button>
      </SLDialog.Trigger>
      <SLDialog.Content>
        <div className="flex flex-col items-center space-y-2">
          <ArtworkImage
            imageId={song?.artworkId}
            size="384x384"
            className={cn(
              "h-64 w-64 min-w-[16rem] rounded-lg sm:h-72 sm:w-72 md:h-80 md:w-80 lg:h-96 lg:w-96",
              {
                "animate-pulse": isUploading || isLoading,
              },
            )}
            // src="/assets/artwork-samples/test.jpg"
            // alt="Album Art"
          />
          {canEditSong(role) && (
            <div className="flex w-full items-center justify-between">
              <Input
                type="file"
                className="cursor-pointer rounded-lg file:cursor-pointer"
                containerClassName="w-full"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          )}
        </div>
      </SLDialog.Content>
    </SLDialog.Root>
  );
};
