export type UserSongRole = "owner" | "writer" | "viewer";

export function isSongOwner(role?: UserSongRole) {
  return role === "owner";
}

export function canEditSong(role?: UserSongRole) {
  return role === "owner" || role === "writer";
}

export function canViewSong(role?: UserSongRole) {
  return role === "owner" || role === "writer" || role === "viewer";
}
