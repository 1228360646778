import { useEffect, useState } from "react";
import { NodeViewProps } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import { Audio } from "@songleaf/ui";
import { ArrowDownToLine, Copy, Trash2 } from "lucide-react";

import { NodeMenu } from "../node-menu";
import { Cache } from "../../../../_utils/cache/cache";
import { useCurrentSong } from "../../../../api/songs";
import { downloadFileFromUrl } from "@/_utils/helpers";
import { DropdownItem } from "@songleaf/ui/src/dropdown/dropdown-composed";
import { canEditSong } from "@/_utils/permissions";
import { AudioPubSub } from "@/_utils/events";

export const InlineAudioComponent: React.FC<NodeViewProps> = ({
  editor,
  node,
  deleteNode,
  getPos,
}) => {
  const { songId, role } = useCurrentSong();
  const [url, setUrl] = useState<string | undefined>(undefined);

  // console.log("InlineAudioComponent", {
  //   editor,
  //   node,
  //   deleteNode,
  //   getPos,
  // });

  useEffect(() => {
    (async () => {
      if (!node.attrs.id || !songId) {
        return;
      }

      const sub = async (payload: { audioId: string }) => {
        if (payload.audioId === node.attrs.id) {
          // do something with the payload
          const cacheResponse = await Cache.Audio.get([songId, node.attrs.id]);
          if (cacheResponse) {
            setUrl(cacheResponse.url);
            AudioPubSub.unsubscribe(sub);
          }
        }
      };

      const cacheResponse = await Cache.Audio.get([songId, node.attrs.id]);
      if (cacheResponse) {
        setUrl(cacheResponse.url);
      } else {
        // subscribe to wait for the cache to be updated
        AudioPubSub.subscribe(sub);
        return () => AudioPubSub.unsubscribe(sub);
      }
    })();
  }, [node.attrs.id]);

  const download = async () => {
    if (!url) {
      return;
    }

    await downloadFileFromUrl(url, node.attrs.id);
  };

  const createLine = () => {
    editor
      .chain()
      .insertContentAt(getPos() + node.nodeSize, {
        type: "line",
      })
      .focus()
      .run();
  };
  const copyLine = () => {
    editor
      .chain()
      .insertContentAt(getPos() + node.nodeSize, {
        type: node.type.name,
        attrs: node.attrs,
      })
      .focus()
      .run();
  };

  const dropdownItems: DropdownItem[] = [
    {
      key: `${node.attrs.id}-download`,
      type: "basic",
      label: "Download",
      onClick: download,
      disabled: !url,
      icon: <ArrowDownToLine size={12} />,
    },
  ];

  if (canEditSong(role)) {
    dropdownItems.push(
      {
        key: `${node.attrs.id}-duplicate`,
        type: "basic",
        label: "Duplicate",
        onClick: () => copyLine(),
        icon: <Copy size={12} />,
      },
      {
        key: `${node.attrs.id}-delete`,
        type: "basic",
        label: "Delete",
        color: "danger",
        onClick: () => deleteNode(),
        icon: <Trash2 size={12} />,
      },
    );
  }

  return (
    <NodeViewWrapper className="inline-audio-node">
      <div className="inline-audio group/inline-audio relative">
        {/* <NodeViewContent className="inline-audio-content"> */}
        <Audio.PlayerTiny url={url} loading={!url} />
        {/* <div className="peer-hover/audio-tiny-player:bottom-6 peer-hover/audio-tiny-player:bg-surface-level-2 absolute bottom-1 right-1 -z-10 inline-block rounded-t-md bg-surface-base px-1 transition-all ease-out">
          <div className="inline-audio-title pb-2 text-[8px]">
            {node.attrs.id}
          </div>
        </div> */}
        {/* </NodeViewContent> */}
        <NodeMenu
          parentType="inline-audio"
          plusAction={canEditSong(role) ? () => createLine() : undefined}
          dropdownItems={dropdownItems}
        />
      </div>
    </NodeViewWrapper>
  );
};
