import { Extension, Editor } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { Plugin, PluginKey, EditorState } from "@tiptap/pm/state";
import { DecorationSet, Decoration } from "@tiptap/pm/view";

interface ChordOptions {
  regex: RegExp;
  shouldRender: (state: EditorState, pos: number, node: Node) => boolean;
  editor: Editor;
}

export const ChordPlugin = ({ editor, regex, shouldRender }: ChordOptions) => {
  return new Plugin({
    key: new PluginKey("chord"),
    state: {
      init() {
        return DecorationSet.empty;
      },
      apply(_transaction, _decorationSet, _prevState, currentState) {
        const { selection } = currentState;
        const decorations: Decoration[] = [];

        return (
          currentState.doc.descendants((node, pos) => {
            const sr = shouldRender(currentState, pos, node);

            if (node.isText && node.text && sr) {
              let matches;
              for (; (matches = regex.exec(node.text)); ) {
                const start = pos + matches.index;
                const end = start + matches[0].length;
                // concat matches 1-8 to get the chord content
                const content = matches.slice(1).join("");

                if (content) {
                  const len = selection.$from.pos - selection.$to.pos;
                  const isAnchorInside =
                    selection.$anchor.pos >= start &&
                    selection.$anchor.pos <= end;
                  const isSelectionInside =
                    selection.$from.pos >= start && selection.$from.pos <= end;
                  /** is no selection and anchor (aka cursor in this case) inside */
                  const isCursorInside =
                    (0 === len && isAnchorInside) || isSelectionInside;

                  (isCursorInside && editor.isEditable) ||
                    decorations.push(
                      Decoration.widget(start, () => {
                        const el = document.createElement("span");
                        el.classList.add("songleaf-chord");
                        editor.isEditable &&
                          el.classList.add("songleaf-chord-editable");
                        el.innerHTML = content;
                        return el;
                      }),
                    ),
                    decorations.push(
                      Decoration.inline(start, end, {
                        class:
                          isCursorInside && editor.isEditable
                            ? "songleaf-chord-editor songleaf-chord-editor-visible"
                            : "songleaf-chord-editor songleaf-chord-editor-hidden",
                      }),
                    );
                }
              }
            }
          }),
          decorations.length > 0
            ? DecorationSet.create(currentState.doc, decorations)
            : DecorationSet.empty
        );
      },
    },
    props: {
      decorations(state) {
        return this.getState(state);
      },
    },
  });
};

export const defaultShouldRender = (editor: EditorState, pos: number) =>
  !("codeBlock" === editor.doc.resolve(pos).parent.type.name);

export const Chords = Extension.create({
  name: "Chord",
  addOptions: () => ({
    // regex: /\[([^\]]*)\]/gi,
    regex:
      /\[([ABDEG](b|♭)|[ACDFG](#|♯)?|[A-G])(maj|min|[Mm+°ø])?(6|7|9|\/)*(aug|d[io]m|add|alt|sus|\+)?(#|♯|b|♭)?(2|4|5|6|7|9|11|13)?\]/g,
    shouldRender: defaultShouldRender,
  }),
  addProseMirrorPlugins() {
    return [
      ChordPlugin({
        ...this.options,
        editor: this.editor,
      }),
    ];
  },
});
