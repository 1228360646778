import { useEffect, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer-record-plugin-wav";

import { formatTime } from "../_utils/functions";

interface PlayerTimeProps {
  wavesurfer: WaveSurfer | null;
}
export const PlayerTime: React.FC<PlayerTimeProps> = ({ wavesurfer }) => {
  const [currentTime, setCurrentTime] = useState(0);
  useEffect(() => {
    if (!wavesurfer) return;

    setCurrentTime(0);

    const subscriptions = [
      wavesurfer.on("timeupdate", (ct) => setCurrentTime(ct * 1000)),
      wavesurfer.on("ready", () => {
        setCurrentTime(wavesurfer.getDuration() * 1000);
      }),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  return (
    <p className="w-full max-w-[32px] text-sm font-medium text-text-secondary">
      {formatTime(currentTime)}
    </p>
  );
};

interface RecorderTimeProps {
  recorder: RecordPlugin | null;
}
export const RecorderTime: React.FC<RecorderTimeProps> = ({ recorder }) => {
  const [currentTime, setCurrentTime] = useState(0);
  useEffect(() => {
    if (!recorder) return;

    const subscriptions = [
      recorder.on("record-progress", (ct) => setCurrentTime(ct)),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [recorder]);

  return (
    <p className="w-full max-w-[32px] text-sm font-medium text-text-secondary">
      {formatTime(currentTime)}
    </p>
  );
};
