import React from "react";
import classnames from "classnames";
import * as RadixPopover from "@radix-ui/react-popover";
import {
  PopoverProps as RadixPopoverProps,
  PopoverTriggerProps as RadixPopoverTriggerProps,
  PopoverContentProps as RadixPopoverContentProps,
  PopoverCloseProps as RadixPopoverCloseProps,
  PopoverArrowProps as RadixPopoverArrowProps,
} from "@radix-ui/react-popover";
import { X } from "lucide-react";

export const PopoverPrimitive = RadixPopover;
export const PopoverRoot = RadixPopover.Root;
export type PopoverRootProps = RadixPopoverProps;
export const PopoverTrigger = RadixPopover.Trigger;
export type PopoverTriggerProps = RadixPopoverTriggerProps;
export const PopoverClose = RadixPopover.Close;
export type PopoverCloseProps = RadixPopoverCloseProps;
export const PopoverArrow = RadixPopover.Arrow;
export type PopoverArrowProps = RadixPopoverArrowProps;

export interface PopoverContentProps extends RadixPopoverContentProps {
  title?: string;
}

const contentClassnames = classnames(
  "adiago-popover-content",
  "z-20 relative rounded-lg shadow-xs drop-shadow-lg p-2 bg-surface-base text-text-primary",
);

export const PopoverContent: React.FC<PopoverContentProps> = ({
  children,
  title,
  ...props
}) => {
  return (
    <RadixPopover.Content
      collisionPadding={8}
      {...props}
      className={contentClassnames}
    >
      <div className="flex justify-between">
        {title && (
          <div className="adiago-popover-title pl-1 pt-0.5 text-sm font-semibold text-text-primary">
            {title}
          </div>
        )}
        <RadixPopover.Close className="adiago-popover-close rounded-md p-0.5 hover:bg-surface-level-2">
          <X size={18} />
        </RadixPopover.Close>
      </div>
      {children}
    </RadixPopover.Content>
  );
};

PopoverContent.displayName = "PopoverContent";
