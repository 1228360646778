import {
  DialogRoot,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogButtonGroup,
  DialogPrimitive,
} from "./dialog";

const Dialog = {
  Primitive: DialogPrimitive,
  Root: DialogRoot,
  Trigger: DialogTrigger,
  Close: DialogClose,
  Content: DialogContent,
  Title: DialogTitle,
  Description: DialogDescription,
  ButtonGroup: DialogButtonGroup,
};

export default Dialog;
