import { useState } from "react";
// import { useAuth } from "@clerk/clerk-react";
import { AudioFile } from "@songleaf/db/schema";
import { createTrpc, trpc } from "../_utils/trpc";
import { getClerkToken } from "../_utils/auth";

interface UseAllSongAudioOptions {
  onSuccess?: (data: AudioFile[]) => void;
}
export const useAllSongAudio = (
  songId: string,
  { onSuccess }: UseAllSongAudioOptions,
) => {
  return trpc.audio.getAllAudioFiles.useQuery(songId, {
    onSuccess,
  });
};

export interface UseUploadAudioFileOptions {
  blob?: Blob;
  uuid?: string;
}

export const useUploadAudioFile = () => {
  const [isUploading, setIsUploading] = useState(false);
  const tutils = trpc.useContext();

  const upload = async (
    songId: string,
    { blob, uuid = crypto.randomUUID() }: UseUploadAudioFileOptions,
  ) => {
    if (!blob) {
      return;
    }

    setIsUploading(true);

    const result = await uploadAudioFile(songId, { blob, uuid });
    if (!result) {
      setIsUploading(false);
      return;
    }

    // set track in audio list query cache
    const prev = tutils.audio.getAllAudioFiles.getData(songId);
    if (!prev) {
      console.log("audio files - no prev");

      return;
    }
    tutils.audio.getAllAudioFiles.setData(songId, [result, ...prev]);

    setIsUploading(false);

    return result;
  };

  return {
    isUploading,
    uploadAudioFile: upload,
  };
};

export const useUpdateAudioFile = (
  songId: string,
  audioFileId: string,
  updatedName: string,
) => {
  const tutils = trpc.useContext();
  return trpc.audio.updateAudioFile.useMutation({
    onMutate() {
      const prev = tutils.audio.getAllAudioFiles.getData(songId);
      if (!prev) return;

      tutils.audio.getAllAudioFiles.setData(songId, [
        ...prev.map((f) => {
          if (f.id === audioFileId) {
            return {
              ...f,
              filename: updatedName || f.filename,
            };
          }
          return f;
        }),
      ]);
    },
  });
};

export const useDeleteAudioFile = (songId: string) => {
  const tutils = trpc.useContext();

  return trpc.audio.deleteAudioFile.useMutation({
    onMutate(audioFileId) {
      const prev = tutils.audio.getAllAudioFiles.getData(songId);
      if (!prev) return;
      tutils.audio.getAllAudioFiles.setData(songId, [
        ...prev.filter((f) => f.id !== audioFileId),
      ]);
    },
  });
};

export const getAudioFile = async (audioFileId: string) => {
  const token = await getClerkToken();

  const $trpc = createTrpc(token);

  return $trpc.audio.getAudioFile.query(audioFileId);
};

export const getAudioFileUrl = async (audioFileId: string) => {
  const token = await getClerkToken();
  const $trpc = createTrpc(token);

  return $trpc.audio.getAudioFileGetUrl.query(audioFileId);
};

export const getAudioFileBlob = async (audioFileId: string) => {
  const audioFileUrlResponse = await getAudioFileUrl(audioFileId);

  const response = await fetch(audioFileUrlResponse.url);
  return response.blob();
};

export const uploadAudioFile = async (
  songId: string,
  { blob, uuid = crypto.randomUUID() }: UseUploadAudioFileOptions,
) => {
  if (!blob) {
    return;
  }

  try {
    const token = await getClerkToken();

    const $trpc = createTrpc(token);

    // create audio file
    const { id: audioFileId } = await $trpc.audio.createAudioFile.mutate({
      songId,
      uuid,
      filename: "Take", // try to get section header
    });

    // get audio put url
    const { url: putUrl, potentialStorageId } =
      await $trpc.audio.getAudioFilePutUrl.query(audioFileId);

    // upload audio file
    await fetch(putUrl, {
      method: "PUT",
      body: blob,
    });

    // set audio storage id
    const audioFile = await $trpc.audio.updateAudioFile.mutate({
      audioFileId,
      storageId: potentialStorageId,
    });

    return audioFile;
  } catch (error) {
    console.error("uploadAudioFile: ", error);
  }
};

export const isAudioFileUploaded = async (uuid: string) => {
  const audioFile = await getAudioFile(uuid);

  // missing storage id means the file is not uploaded to cloudflare but
  // we may not need to use that as a check
  if (!audioFile || !audioFile.storageId) {
    return false;
  }

  return true;
};
