import { useEffect, useState } from "react";
import { Cache } from "@/_utils/cache/cache";
import { ImageSize } from "@/_utils/cache/kv-image";

interface ArtworkImageProps {
  imageId?: string | null;
  size: ImageSize;
  className?: string;
}

// what could possibly go wrong with this
let artworkImageUrlMemoryCache: Record<string, string> = {};

export const ArtworkImage: React.FC<ArtworkImageProps> = ({
  imageId,
  size,
  className,
}) => {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState<string | undefined>(undefined);

  const getUrl = async () => {
    if (!imageId) return;

    if (artworkImageUrlMemoryCache[imageId + ":" + size]) {
      setUrl(artworkImageUrlMemoryCache[imageId + ":" + size]);
      setLoading(false);
      return;
    }

    const cacheResponse = await Cache.Image.get([imageId, size]);

    if (cacheResponse) {
      setUrl(cacheResponse.url);
      artworkImageUrlMemoryCache[imageId + ":" + size] = cacheResponse.url;
    }
    setLoading(false);
  };

  useEffect(() => {
    getUrl();
  }, [imageId]);

  if (imageId && loading) {
    return (
      <div className={className}>
        <div className="h-full w-full animate-pulse rounded-lg bg-surface-level-3"></div>
      </div>
    );
  }

  return (
    <img
      className={className}
      src={imageId && url ? url : "/assets/empty-artwork.jpg"}
      alt="Album Art"
    />
  );
};
