import { ImageFileCropDimensions } from "@songleaf/db/schema";

import { KVBase } from "./kv-base";

import {
  getImageFileBlob,
  isImageFileUploaded,
  uploadImageFile,
} from "../../api/image";

export interface KVImageValue {
  blob: Blob;
  size: ImageSize | "original";
  songId?: string;
  cropDimensions?: ImageFileCropDimensions;
  uploadPending?: boolean;
  uploadLock?: boolean;
}
export interface KVImageReturn {
  blob: Blob;
  url: string;
}

export type ImageSize = "32x32" | "384x384" | "1024x1024";

export const KVImage = {
  get: async (
    [key, variant]: [string, ImageSize],
    params?: {
      songId?: string;
      cropDimensions?: ImageFileCropDimensions;
    },
    skipUpload = false,
  ): Promise<KVImageReturn | undefined> => {
    const value: KVImageValue | undefined = await KVBase.get(
      key + ":" + variant,
    );

    if (!value) {
      const blob = await getImage(key, variant);
      return createKVImageReturn(blob);
    }

    if (value.uploadLock === true || skipUpload) {
      return createKVImageReturn(value.blob);
    }

    if (value.uploadPending === true) {
      // check api if upload is complete
      const isUploaded = await isImageFileUploaded(key[0]);
      if (isUploaded) {
        // if uploaded already update the cache
        KVBase.update(key + ":" + variant, { blob: value.blob });
        return createKVImageReturn(value.blob);
      }

      // else if not uploaded yet, try to upload in "background"
      upload(key, value.blob, params);
      // then return the value as is for now
    }

    return createKVImageReturn(value.blob);
  },
  set: async (
    key: string,
    value: Blob,
    params?: {
      songId?: string;
      cropDimensions?: ImageFileCropDimensions;
    },
  ) => {
    await upload(key, value, params);
  },
  update: async (
    [key, variant]: [string, ImageSize],
    value: Partial<KVImageValue>,
  ) => {
    return KVBase.update(key + ":" + variant, { _type: "image", ...value });
  },
  del: async (key: [string, ImageSize], ignoreLock = false) => {
    const value: KVImageValue | undefined = await KVBase.get(key.join(":"));
    if (!value) return;
    if (value.uploadLock === true && ignoreLock) return;

    return KVBase.del(key.join(":"));
  },
};

const createKVImageReturn = async (blob?: Blob) => {
  if (!blob) return;
  const url = URL.createObjectURL(blob);
  return { blob, url };
};

const getImage = async (key: string, variant: ImageSize) => {
  try {
    const imageFileBlob = await getImageFileBlob(key, variant);
    await KVImage.update([key, variant], { blob: imageFileBlob });
    return imageFileBlob;
  } catch (err) {
    return;
  }
};

const upload = async (
  key: string,
  blob: Blob,
  params?: {
    songId?: string;
    cropDimensions?: ImageFileCropDimensions;
  },
) => {
  // upload the file
  try {
    // lock the record, queue the upload
    await KVBase.update(key + ":original", {
      blob: blob,
      uploadLock: true,
      uploadPending: true,
    });

    await uploadImageFile({
      blob: blob,
      uuid: key,
      cropDimensions: params?.cropDimensions,
    });
    // on success, update the cache
    await KVBase.set(key + ":original", { _type: "image", blob: blob });
  } catch (err) {
    // on failure, unlock the record
    await KVBase.update(key + ":original", {
      blob: blob,
      songId: params?.songId,
      uploadLock: false,
      uploadPending: true,
    });
  }
};
