import { ClerkProvider, useClerk } from "@clerk/clerk-react";
import React, { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setClerk } from "../../_utils/auth";

if (!import.meta.env.VITE_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

/**
 * Requires react router
 */
export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <ClerkProvider publishableKey={clerkPubKey} navigate={(to) => navigate(to)}>
      <ClerkSingletonConcierge>{children}</ClerkSingletonConcierge>
    </ClerkProvider>
  );
};

const ClerkSingletonConcierge: React.FC<PropsWithChildren> = ({ children }) => {
  const clerk = useClerk();
  useEffect(() => {
    if (clerk) {
      setClerk(clerk);
    }
  }, [clerk]);

  return <>{children}</>;
};
