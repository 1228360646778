import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { SectionHeaderComponent } from "./section-header-component";

export const SectionHeaderNode = Node.create({
  name: "sectionHeader",
  group: "block",
  content: "inline*",
  defining: true,
  // allowGapCursor: false,
  // isolating: true,
  parseHTML() {
    return [
      {
        tag: "h2",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["h2", mergeAttributes(HTMLAttributes), 0];
  },
  addNodeView() {
    return ReactNodeViewRenderer(SectionHeaderComponent);
  },
});
