import { Extension, Editor } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { Plugin, PluginKey, EditorState } from "@tiptap/pm/state";
import { DecorationSet, Decoration } from "@tiptap/pm/view";

interface LineOptions {
  shouldRender: (state: EditorState, pos: number, node: Node) => boolean;
  editor: Editor;
}

export const LinePlugin = ({ editor, shouldRender }: LineOptions) => {
  return new Plugin({
    key: new PluginKey("line"),
    state: {
      init() {
        return DecorationSet.empty;
      },
      apply(_transaction, _decorationSet, _prevState, currentState) {
        const { selection } = currentState;
        const decorations: Decoration[] = [];

        return (
          currentState.doc.descendants((node, pos) => {
            const sr = shouldRender(currentState, pos, node);

            if (node.isText && sr) {
              const start = pos;
              const end = start + node.nodeSize;

              const len = selection.$from.pos - selection.$to.pos;
              const isAnchorInside =
                selection.$anchor.pos >= start && selection.$anchor.pos <= end;
              const isSelectionInside =
                selection.$from.pos >= start && selection.$from.pos <= end;
              /** is no selection and anchor (aka cursor in this case) inside */
              const isCursorInside =
                (0 === len && isAnchorInside) || isSelectionInside;

              decorations.push(
                Decoration.inline(start - 1, end, {
                  class:
                    isCursorInside && editor.isEditable
                      ? "songleaf-line-editing"
                      : "",
                }),
              );
            }
          }),
          decorations.length > 0
            ? DecorationSet.create(currentState.doc, decorations)
            : DecorationSet.empty
        );
      },
    },
    props: {
      decorations(state) {
        return this.getState(state);
      },
    },
  });
};

export const defaultShouldRender = (editor: EditorState, pos: number) =>
  "line" === editor.doc.resolve(pos).parent.type.name;

export const Lines = Extension.create({
  name: "Line",
  addOptions: () => ({
    shouldRender: defaultShouldRender,
  }),
  addProseMirrorPlugins() {
    return [
      LinePlugin({
        ...this.options,
        editor: this.editor,
      }),
    ];
  },
});
