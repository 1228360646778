import { WritersList } from "@/components/writers-list";
import { useCurrentSong } from "../../api/songs";
import { ArtworkDialog } from "./song-artwork-dialog";
import { SongShare } from "./song-share/song-share";
import { SongTitleInput } from "./song-title-input";
import { canEditSong } from "@/_utils/permissions";

export const SongDetails: React.FC = () => {
  const { song, role } = useCurrentSong();

  return (
    <div className="flex w-full items-center justify-between space-x-3 rounded-xl bg-surface-level-1 p-2 shadow-xs">
      <ArtworkDialog />
      {/* <button className="relative z-10 h-12 w-12 rounded-lg">
        <div className="group/artwork absolute top-0 origin-top-left rounded-lg shadow-none transition-all hover:scale-110 hover:shadow-lg">
          <img
            className="h-12 w-12 rounded-lg"
            src="/assets/artwork-samples/car.png"
            alt="Album Art"
          />
          <img
            className="group-hover/artwork:opacity-70 group-hover/artwork:delay-100 delay-0 absolute right-1 bottom-1 opacity-0 transition-all"
            src="/assets/icons/arrow-bottom-right.svg"
          />
        </div>
      </button> */}
      <div className="flex flex-1 flex-col">
        <SongTitleInput />

        <div className="ml-1 flex space-x-2 text-xs">
          <WritersList writers={song?.writers} />
        </div>
      </div>

      {/* <div className="flex-1" /> */}
      {/* <SongAlbumArt />
      <div className="flex items-center justify-between">
        <SongTitleInput />
        <SongActions />
      </div>
      <SongSections /> */}
      {/* 
      an editor can access this menu to copy the song link
      however they cannot invite other users to the song
      */}
      {canEditSong(role) && <SongShare />}
    </div>
  );
};
