import { Button } from "@songleaf/ui";
import React from "react";
import { trpc } from "../../../../_utils/trpc";

export const UserPreferencesBackfill: React.FC = () => {
  const { mutate: backfillUserPreferences, isLoading: backfillLoading } =
    trpc.theMatrix.backfillUserPreferences.useMutation({
      async onSuccess(count) {
        alert(`backfilled ${count} user preferences`);
      },
    });

  return (
    <Button onClick={() => backfillUserPreferences()} loading={backfillLoading}>
      backfill user preferences
    </Button>
  );
};
