/**
 * Downloads a file from a URL.
 * If the url is a blob, it fetch the blob and then download it.
 * @param url
 * @param name
 */
export async function downloadFileFromUrl(url: string, name: string) {
  try {
    const blob = await fetch(url).then((res) => res.blob());

    var link = document.createElement("a");
    link.download = name;
    link.href = URL.createObjectURL(blob);
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

/**
 * Downloads a blob as a file
 * @param uri
 * @param name
 */
export function downloadURI(uri: string, name: string) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
