import React from "react";
import classnames from "classnames";
import { Label } from "../label";
import { Loader } from "../loader";

export type TInputVariants = "standard" | "invisible";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  icon?: React.ReactNode;
  variant?: TInputVariants;
  loading?: boolean;
  containerClassName?: string;
}

const baseClasses = classnames(
  "adiago-input",
  "z-0 text-text-primary p-2 text-xs rounded w-full transition-all duration-75",
);
const variantClasses: Record<TInputVariants, string> = {
  standard:
    "bg-surface-base border border-surface-level-2 outline-surface-brand file:border-0 file:bg-transparent file:text-sm file:font-medium",
  invisible: "bg-transparent outline-none focus:bg-surface-level-2",
};

const iconClasses =
  "absolute left-2 top-2 first:w-4 first:h-4 z-10 first:text-neutral-500";

export const Input: React.FC<InputProps> = ({
  /*icon,*/ label,
  variant = "standard",
  icon,
  loading,
  type = "text",
  className,
  containerClassName,
  ...props
}) => {
  const classNames = classnames(
    baseClasses,
    variantClasses[variant],
    icon && "pl-7",
    loading && "pr-7",
    props.disabled && "bg-surface-level-1 text-text-secondary",
    className,
  );
  const containerClasses = classnames(
    "adiago-input-container relative z-0",
    containerClassName,
  );

  return (
    <div className={containerClasses}>
      {icon ? <div className={iconClasses}>{icon}</div> : null}
      {label && (
        <Label htmlFor={props.id} className="block pb-1 text-xs">
          {label}
        </Label>
      )}
      <input type={type} className={classNames} {...props} />

      {loading && (
        <div className="absolute right-2 top-2">
          <Loader size="sm" />
        </div>
      )}
    </div>
  );
};

Input.displayName = "Input";
