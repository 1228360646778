export function RecordIcon({ scale = 1, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11 * scale}
      height={12 * scale}
      fill="none"
      viewBox="0 0 11 12"
      style={style}
    >
      <path
        fill="#2D342F"
        fillRule="evenodd"
        d="M5.365.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm0 2.75a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
