import { useNavigate } from "react-router-dom";
import { trpc } from "../_utils/trpc";
import { AudioPubSub } from "@/_utils/events";

export const useCurrentUser = () => {
  return trpc.user.get.useQuery(undefined, {
    staleTime: Infinity,
  });
};

export const useEventSubscriptions = () => {
  const tutils = trpc.useContext();
  const navigate = useNavigate();
  return trpc.user.eventsSubscription.useSubscription(undefined, {
    onData({ resourceType, event, resourceId, metadata }) {
      // console.log("event", { resourceType, event, resourceId, metadata });

      if (resourceType === "song") {
        if (event === "update") {
          tutils.song.byId.invalidate(resourceId);
          tutils.song.all.invalidate();
        }
        if (event === "delete") {
          navigate("/");
          // @todo put a toast here
          tutils.song.all.invalidate();
        }
      }

      if (resourceType === "collab" || resourceType === "song-invite") {
        tutils.song.collborators.invalidate(metadata.songId);
      }

      if (resourceType === "collab" && event === "accept") {
        tutils.song.all.invalidate();
      }

      // collaborator was upgraded or downgraded
      if (resourceType === "collab" && event === "update") {
        tutils.song.byId.invalidate(metadata.songId);
      }
      // collaborator was removed and needs to be kicked out of the song
      if (resourceType === "collab" && event === "delete") {
        // should just remove the song instead
        tutils.song.all.invalidate();
        // @todo put a toast here
        navigate("/");
      }

      if (resourceType === "audio-file") {
        tutils.audio.getAllAudioFiles.invalidate(metadata.songId);
        if (event === "update") {
          AudioPubSub.publish({
            audioId: resourceId,
          });
        }
      }

      if (resourceType === "notification") {
        tutils.notification.all.invalidate();
        tutils.notification.unreadCount.invalidate();
      }
    },
  });
};
