import { EditorToolbarRecorder } from "./editor-toolbar-recorder";
import { useCurrentSong } from "../../api/songs";
import { useEditorToolbarStore } from "../../state/editor-toolbar";
import { ChordIcon } from "../icons/chords";
import { RecordIcon } from "../icons/record";
import { CloseIcon } from "../icons/close";
import { EditorToolbarChords } from "./editor-toolbar-chords";
import { Cache } from "@/_utils/cache/cache";
import { EditorToolbarMicInput } from "./editor-toolbar-mic-input";
import { canEditSong } from "@/_utils/permissions";

export const EditorToolbar = () => {
  const { role } = useCurrentSong();
  const { state, setState } = useEditorToolbarStore();

  if (!canEditSong(role)) {
    return null;
  }

  return (
    <div className="absolute left-1/2 bottom-4 flex w-full max-w-3xl -translate-x-1/2 flex-col items-center justify-between space-y-2 px-12">
      {state === "recorder" && (
        <EditorToolbarRecorder exit={() => setState("closed")} />
      )}
      {state === "chords" && <EditorToolbarChords />}

      <ToolbarToolButtons />
    </div>
  );
};

const ToolbarToolButtons = () => {
  const { state, setState, temporaryAudioId, setTemporaryAudioId } =
    useEditorToolbarStore();

  const handleCancel = () => {
    if (state === "recorder") {
      if (temporaryAudioId) {
        Cache.Audio.del(temporaryAudioId);
        setTemporaryAudioId(undefined);
      }
      setState("closed");
    } else if (state === "chords") {
      setState("closed");
    }
  };

  return (
    <div className="relative flex items-center justify-between space-x-[2px] rounded-full bg-surface-base p-[2px] text-xs text-text-primary-1 shadow-xs drop-shadow-lg transition-all">
      {state !== "closed" ? (
        <button
          className="flex items-center justify-between space-x-1 rounded-full bg-surface-base py-1.5 pl-2.5 pr-3 font-semibold transition-colors hover:bg-surface-level-2"
          onClick={handleCancel}
        >
          <CloseIcon scale={1} style={{ marginBottom: "1px" }} />
          <span>Close</span>
        </button>
      ) : (
        <>
          <button
            className="flex items-center justify-between space-x-1 rounded-[64px] rounded-r-[12px] bg-surface-base py-1.5 pl-3 pr-2.5 font-semibold transition-colors hover:bg-surface-level-2"
            onClick={() => {
              setState("chords");
            }}
          >
            <ChordIcon style={{ marginBottom: "1px" }} />
            <span>Chords</span>
          </button>
          <div className="h-5 w-[1px] bg-surface-level-2" />
          <div className="group/mic">
            <button
              className="peer/mic relative z-10 flex items-center justify-between space-x-1 rounded-[64px] rounded-l-[12px] bg-surface-base py-1.5 pr-3 pl-2.5 font-semibold transition-colors hover:bg-surface-level-2"
              onClick={() => {
                setState("recorder");
              }}
            >
              <RecordIcon style={{ marginBottom: "1px" }} />
              <span>Record</span>
            </button>
            <div className="songleaf-mic-select-container peer-hover/mic:block absolute bottom-10 -left-[30%] hidden translate-x-1/2 hover:block">
              <div className="group-hover/mic:block absolute -bottom-[10px] left-[50%] z-0 hidden h-14 w-[120%] -translate-x-1/2 bg-green-400/0"></div>
              <div className="group-hover/mic:block absolute top-[20px] left-[91%] z-0 hidden h-12 w-[62px] -translate-x-1/2 bg-blue-400/0"></div>
              <EditorToolbarMicInput />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
