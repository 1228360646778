import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import { WaveSurferOptions } from "wavesurfer.js";

import { useWavesurfer } from "./use-wavesurfer";
import { Loader } from "../loader";
import { PlayerTime } from "./audio-player-time";

export interface AudioPlayerSimpleProps {
  className?: string;
  classOverride?: string;
  url?: string;
  loading?: boolean;
  options?: WaveSurferOptions;
}

const initialOptions: Omit<WaveSurferOptions, "container"> = {
  height: 42,
  waveColor: "#2D342F",
  progressColor: "#36EA73",
  cursorColor: "#36EA73",
  cursorWidth: 2,
  barWidth: 2,
  barGap: 2,
  barRadius: 30,
  barHeight: 0.96,

  normalize: false,
  minPxPerSec: 1,
  fillParent: true,
  // url: "/wavesurfer-code/examples/audio/audio.wav",
  mediaControls: false,
  audioRate: 1,
  autoScroll: false,
  autoCenter: true,
  dragToSeek: true,
};

export const AudioPlayerOmni: React.FC<AudioPlayerSimpleProps> = ({
  className,
  url,
  loading,
  options = {},
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useWavesurfer(containerRef, {
    ...initialOptions,
    ...options,
    url,
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const [ready, setReady] = useState(false);

  // On play button click
  const onTogglePlaying = useCallback(() => {
    wavesurfer?.isPlaying() ? wavesurfer.pause() : wavesurfer?.play();
  }, [wavesurfer]);

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!wavesurfer) return;

    setIsPlaying(false);

    const subscriptions = [
      wavesurfer.on("play", () => setIsPlaying(true)),
      wavesurfer.on("pause", () => setIsPlaying(false)),
      wavesurfer.on("ready", () => setReady(true)),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  const classNames = classnames("adiago-audio-omni", className);

  return (
    <div className={classNames}>
      <div className="flex items-center space-x-2">
        <button
          className="flex h-7 w-7 min-w-[1.75rem] items-center justify-center rounded-full bg-gray-700 text-surface-base"
          onClick={onTogglePlaying}
          disabled={(wavesurfer && !ready) || loading}
        >
          {(wavesurfer && !ready) || loading ? (
            <Loader className="h-4 w-4 fill-surface-base" />
          ) : isPlaying ? (
            <PauseIcon className="h-4 w-4" />
          ) : (
            <PlayIcon className="h-4 w-4 translate-x-[1px]" />
          )}
        </button>

        {url ? (
          <div
            className="w-full min-w-[200px] rounded-lg bg-djent-75 px-1"
            ref={containerRef}
          />
        ) : (
          <div className="w-full flex-shrink-[2]" />
        )}

        <PlayerTime wavesurfer={wavesurfer} />
      </div>
    </div>
  );
};
