import { Button } from "@songleaf/ui";
import React from "react";
import { trpc } from "../../../../_utils/trpc";

export const UserNotificationPreferencesBackfill: React.FC = () => {
  const {
    mutate: backfillUserNotificationPreferences,
    isLoading: backfillLoading,
  } = trpc.theMatrix.backfillUserNotificationPreferences.useMutation({
    async onSuccess(count) {
      alert(`backfilled ${count} user notification preferences`);
    },
  });

  return (
    <Button
      onClick={() => backfillUserNotificationPreferences()}
      loading={backfillLoading}
    >
      backfill user notification preferences
    </Button>
  );
};
