import { Editor, Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

import { Cache } from "@/_utils/cache/cache";
import { uploadCachedAudio } from "@/_utils/cache/kv-audio";

export type FileHandlePluginOptions = {
  /**
   * The plugin key.
   * @default 'fileHandler'
   */
  key?: PluginKey;
  songId?: string;
  /**
   * The editor instance.
   */
  editor: Editor;
  /**
   * An array of allowed mimetypes
   *
   * example: ['image/jpeg', 'image/png']
   */
  allowedMimeTypes?: string[];
  /**
   * The onPaste callback that is called when a file is pasted.
   * @param editor the editor instance
   * @param files the File array including File objects
   * @param pasteContent the pasted content as HTML string - this is only available if there is also html copied to the clipboard for example by copying from a website
   * @returns Returns nothing.
   */
  onPaste?: (editor: Editor, files: File[], pasteContent?: string) => void;
  /**
   * The onDrop callback that is called when a file is dropped.
   * @param editor the editor instance
   * @param files the File array including File objects
   * @returns Returns nothing.
   */
  onDrop?: (editor: Editor, files: File[], pos: number) => void;
};
export type FileHandlerOptions = {} & Omit<
  FileHandlePluginOptions,
  "key" | "editor"
>;

const FilePlugin = ({
  key,
  editor,
  onPaste,
  onDrop,
  allowedMimeTypes,
}: FileHandlePluginOptions) =>
  new Plugin({
    key: key || new PluginKey("fileHandler"),
    props: {
      handleDrop(editorView, dragEvent) {
        let data = dragEvent.dataTransfer;
        if (!onDrop) return false;
        if (!data || !data.files.length) return false;

        const index = editorView.posAtCoords({
          left: dragEvent.clientX,
          top: dragEvent.clientY,
        });

        let files = Array.from(data.files);
        return (
          allowedMimeTypes &&
            (files = files.filter((e) => allowedMimeTypes.includes(e.type))),
          0 !== files.length &&
            (dragEvent.preventDefault(),
            dragEvent.stopPropagation(),
            onDrop(editor, files, index?.pos || 0),
            !0)
        );
      },
      handlePaste(_editorView, dragEvent) {
        let data = dragEvent.clipboardData;

        if (!onPaste) return false;

        if (!data || !data.files.length) return false;

        let files = Array.from(data.files);

        const content = data.getData("text/html");
        return (
          allowedMimeTypes &&
            (files = files.filter((e) => allowedMimeTypes.includes(e.type))),
          0 !== files.length &&
            (dragEvent.preventDefault(),
            dragEvent.stopPropagation(),
            onPaste(editor, files, content),
            !(content.length > 0))
        );
      },
    },
  });

export const FileHandler = Extension.create({
  name: "fileHandler",
  addOptions: () =>
    ({
      onPaste: void 0,
      onDrop: void 0,
      allowedMimeTypes: void 0,
    } as FileHandlerOptions),
  addProseMirrorPlugins() {
    return [
      FilePlugin({
        key: new PluginKey(this.name),
        editor: this.editor,
        allowedMimeTypes: this.options.allowedMimeTypes,
        onDrop: this.options.onDrop,
        onPaste: this.options.onPaste,
      }),
    ];
  },
});

export const AudioFileHandler = Extension.create({
  name: "audioFileHandler",
  addOptions: () =>
    ({
      songId: undefined,
    } as FileHandlerOptions),
  addProseMirrorPlugins() {
    return [
      FilePlugin({
        key: new PluginKey(this.name),
        editor: this.editor,
        allowedMimeTypes: [
          "audio/aac",
          "audio/flac",
          "audio/wav",
          "audio/webm",
          "audio/mp3",
          "audio/mp4",
        ],
        onDrop: (currentEditor, files, pos) => {
          if (!this.options.songId) return;
          files.forEach((file) => {
            const fileReader = new FileReader();

            fileReader.readAsArrayBuffer(file);

            fileReader.onload = async () => {
              if (!this.options.songId) return;
              if (!fileReader.result) return;

              const uuid = crypto.randomUUID();
              await Cache.Audio.set(
                [this.options.songId, uuid],
                new Blob([fileReader.result]),
              );

              uploadCachedAudio(this.options.songId, uuid);

              currentEditor
                .chain()
                .insertContentAt(pos, {
                  type: "inline-audio",
                  attrs: {
                    id: uuid,
                  },
                })
                .focus()
                .run();
            };
          });
        },
      }),
    ];
  },
});
