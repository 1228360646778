export function ChordIcon({ scale = 1, style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11 * scale}
      height={12 * scale}
      fill="none"
      viewBox="0 0 11 12"
      style={style}
    >
      <g clipPath="url(#clip0_873_11794)">
        <rect
          width="8.008"
          height="8.851"
          x="0.632"
          y="1.552"
          stroke="#1B211D"
          strokeLinejoin="round"
          strokeWidth="1.264"
          rx="1.132"
        ></rect>
        <path
          stroke="#1B211D"
          strokeWidth="1.264"
          d="M3.161 1.763L3.161 10.193"
        ></path>
        <path
          stroke="#1B211D"
          strokeWidth="1.264"
          d="M6.112 1.763L6.112 10.193"
        ></path>
        <circle cx="3.161" cy="4.503" r="1.264" fill="#1B211D"></circle>
        <circle cx="6.112" cy="7.874" r="1.264" fill="#1B211D"></circle>
        <circle cx="8.64" cy="4.503" r="1.264" fill="#1B211D"></circle>
      </g>
      <defs>
        <clipPath id="clip0_873_11794">
          <path
            fill="#fff"
            d="M0 0H10.116V10.16H0z"
            transform="translate(0 .92)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
