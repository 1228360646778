import { UserButton, useAuth } from "@clerk/clerk-react";

export const UserProfile: React.FC = () => {
  const { isSignedIn } = useAuth();

  if (!isSignedIn) {
    return null;
  }

  return (
    <UserButton
      appearance={{
        elements: {
          userButtonAvatarBox: {
            width: "1rem",
            height: "1rem",
          },
          userButtonTrigger: {
            "&:focus": {
              boxShadow: "var(--surface-brand) 0px 0px 0px 3px",
            },
          },
        },
      }}
    />
  );
};
