import { SongEditor } from "../components/song-editor/song-editor";
import { ScrollAreaVertical } from "@songleaf/ui/src/scroll-area/scroll-area";
import { Loader, useDocumentTitle } from "@songleaf/ui";
import { useCurrentSong } from "../api/songs";
import { SongDetails } from "./song/song-details";
import { canEditSong } from "@/_utils/permissions";

export const SongPage: React.FC = () => {
  const { song, role, isLoading } = useCurrentSong();

  useDocumentTitle(song?.title || "Songleaf");

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader size="xl" />
      </div>
    );
  }

  if (!song && !isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        Error loading song
      </div>
    );
  }

  return (
    <ScrollAreaVertical className="h-full">
      <main className="flex h-full flex-col items-center bg-surface-base pt-10 text-text-primary lg:pt-0">
        <div className="flex w-full max-w-3xl flex-col items-center justify-between space-y-2 px-4 py-2">
          <SongDetails />

          {song && (
            <SongEditor song={song} options={{ editable: canEditSong(role) }} />
          )}
        </div>
      </main>
      <div className="h-40" />
    </ScrollAreaVertical>
  );
};
