import { Outlet } from "react-router-dom";
import { HomeSidebar } from "./home-sidebar/home-sidebar";
import { CollabSocketProvider } from "../../components/api/collab-socket-provider";
import { trpc } from "../../_utils/trpc";
import { useEventSubscriptions } from "../../api/users";
import { EditorToolbar } from "../../components/editor-toolbar/editor-toolbar";

export const HomeLayout = () => {
  useEventSubscriptions();
  // prefetch user
  trpc.user.get.useQuery(undefined, {
    staleTime: Infinity,
  });

  return (
    <CollabSocketProvider>
      <div className="adiago-layout h-full">
        <div className="adiago-layout-body flex h-full w-full">
          <HomeSidebar />
          <div className="adiago-layout-view relative z-0 h-full w-full">
            <Outlet />
            <EditorToolbar />
          </div>
        </div>
      </div>
    </CollabSocketProvider>
  );
};
