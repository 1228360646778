import {
  PopoverPrimitive,
  PopoverRoot,
  PopoverContent,
  PopoverArrow,
  PopoverClose,
  PopoverTrigger,
} from "./popover";

const Popover = {
  Primitive: PopoverPrimitive,
  Root: PopoverRoot,
  Content: PopoverContent,
  Arrow: PopoverArrow,
  Close: PopoverClose,
  Trigger: PopoverTrigger,
};

export default Popover;
