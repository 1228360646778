import React from "react";
import { Loader } from "@songleaf/ui";
import { EditorContent } from "@tiptap/react";

import { Song } from "@songleaf/db/schema";

import { SongEditorOptions, useSongEditor } from "./use-song-editor";

import "./song-editor.css";

interface SongEditorProps {
  song: Pick<Song, "id" | "title">;
  options?: SongEditorOptions;
}

export const SongEditor: React.FC<SongEditorProps> = ({ song, options }) => {
  const { editor, isLoading } = useSongEditor(song, options);

  // console.log("SongEditor", {
  //   html: editor?.getHTML(),
  //   json: editor?.getJSON(),
  // });

  if (isLoading) {
    return <Loader />;
  }

  if (!editor) {
    return <div>loading editor</div>;
  }

  return (
    <EditorContent editor={editor} className="w-full" spellCheck="false" />
  );
};
