import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatDistance, format } from "date-fns";
import { Sidebar } from "@songleaf/ui";
import { TRPCOutputs, trpc } from "../../../_utils/trpc";
import { useSidebarStore } from "../../../state/sidebar";
import { useMarkNotificationAsRead } from "../../../api/notifications";

interface HomeSidebarNotificationListitemProps {
  notification: TRPCOutputs["notification"]["all"][number];
}

export const HomeSidebarNotificationListitem = ({
  notification,
}: HomeSidebarNotificationListitemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { songId } = useParams();
  const navigate = useNavigate();
  const tutils = trpc.useContext();
  const [isFocused, setIsFocused] = useState(false);

  const { close: closeSidebar } = useSidebarStore();

  const { mutate: markAsRead } = useMarkNotificationAsRead();

  const onListItemClick: React.MouseEventHandler<HTMLDivElement> = () => {
    if (notification.read_at === null) {
      markAsRead(notification.id);
    }

    ref.current?.focus();
    // this goes to the song or forces a re-render
    navigate(
      notification.song_id || songId
        ? `/song/${notification.song_id || songId}`
        : "/",
    );

    if (notification.song_id) {
      tutils.song.byId.invalidate(notification.song_id);

      // Close sidebar on mobile
      if (window.innerWidth < 768) {
        closeSidebar();
      }
    }
  };

  useEffect(() => {
    if (document.activeElement === ref.current) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  }, [document.activeElement]);

  // if longer than 1 day, show date
  const isOld = Date.now() - notification.created_at.getTime() > 86400000;

  const date = isOld
    ? formatDistance(notification.created_at, new Date(), {
        addSuffix: true,
      })
    : format(new Date(notification.created_at), "h:mm a, LLL do");

  return (
    <Sidebar.ListItem active={isFocused} onClick={onListItemClick} ref={ref}>
      <div className="flex w-full items-center justify-between py-1 pr-1">
        <div className="flex flex-1 flex-col space-y-1">
          <NotifcationMessage notification={notification} />
          <span className="select-none text-[0.625rem] text-text-primary-3">
            {date}
          </span>
        </div>
        {notification.read_at === null && (
          <div className="h-2 w-2 rounded-full bg-surface-brand" />
        )}
      </div>
    </Sidebar.ListItem>
  );
};

const NotifcationMessage = ({
  notification,
}: {
  notification: TRPCOutputs["notification"]["all"][number];
}) => {
  const message = useMemo(() => {
    let m = notification.message;
    m = m.replace(
      "@{user.full_name}",
      notification.originator_full_name || "A collaborator",
    );
    m = m.replace("@{song.title}", notification.song_title || "a song");
    return m;
  }, [
    notification.message,
    notification.originator_full_name,
    notification.song_title,
  ]);

  return <div className="flex-1 select-none">{message}</div>;
};
