import { useClerk } from "@clerk/clerk-react";

let Clerk: ReturnType<typeof useClerk> | null = null;

export const setClerk = (clerk: ReturnType<typeof useClerk>) => {
  Clerk = clerk;
};

export const getClerk = () => {
  if (!Clerk) {
    throw new Error("[Clerk] not initialized");
  }

  return Clerk;
};

export const getClerkToken = async () => {
  if (!Clerk) {
    throw new Error("[Clerk] not initialized");
  }
  if (!Clerk.session) {
    throw new Error("[Clerk] no session");
  }

  const token = await Clerk.session.getToken();

  if (!token) {
    throw new Error("[Clerk] not authenticated");
  }

  return token;
};
