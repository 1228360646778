import { useEffect, useState } from "react";
import { DraggableChords } from "./chords/draggable-chords";
import { Key } from "@/_utils/chords";
import { KeyInput } from "@songleaf/ui";
import { useCurrentSong, useUpdateSong } from "@/api/songs";

export interface AudioTrackBoxRecorderProps {}

let timer: NodeJS.Timeout | undefined;
export const EditorToolbarChords: React.FC<AudioTrackBoxRecorderProps> = () => {
  const { song } = useCurrentSong();
  const [key, setKey] = useState<string>("C");

  useEffect(() => {
    setKey(song?.key || "C");
  }, [song?.key]);

  const { mutate: updateSong } = useUpdateSong(song?.id);

  const onKeyChange = (newKey: string) => {
    setKey(newKey);
    clearTimeout(timer);
    timer = setTimeout(() => {
      updateSong({
        songId: song?.id || "",
        key: newKey,
      });
    }, 3000);
  };

  return (
    <div
      className="group flex w-full max-w-sm flex-col justify-between space-y-1 rounded-lg bg-surface-base shadow-xs drop-shadow-lg transition-all ease-out animate-in fade-in slide-in-from-bottom-3"
      contentEditable={false}
    >
      <div className="flex justify-between p-2 pb-0">
        <KeyInput value={key} setValue={onKeyChange} />
      </div>

      <DraggableChords activeKey={key as Key} />
    </div>
  );
};
