import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { LineComponent } from "./line-component";

export const LineNode = Node.create({
  name: "line",
  group: "sectionContent",
  // zero or one line
  content: "inline*",
  draggable: true,
  parseHTML() {
    return [
      {
        tag: "p",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["p", mergeAttributes(HTMLAttributes), 0];
  },
  addNodeView() {
    return ReactNodeViewRenderer(LineComponent);
  },
});
