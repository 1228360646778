import { uploadCachedAudio } from "@/_utils/cache/kv-audio";
import { useEditorToolbarStore } from "@/state/editor-toolbar";
import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

export type AudioDropHandlePluginOptions = {
  /**
   * The plugin key.
   * @default 'audioDropHandler'
   */
  key?: PluginKey;
  songId?: string;
};
export type AudioDropHandlerOptions = {} & Omit<
  AudioDropHandlePluginOptions,
  "key" | "editor"
>;

const AudioDropPlugin = ({ key, songId }: AudioDropHandlePluginOptions) =>
  new Plugin({
    key: key || new PluginKey("audioDropHandler"),
    props: {
      handleDrop(_editorView, dragEvent) {
        if (!songId) return false;
        // TODO: I wonder if here I could prevent the audio from being dropped inside a line.
        // If it in the middle of a line, it should be dropped below that line
        // console.log(
        //   "handleDrop",
        //   dragEvent,
        //   dragEvent.dataTransfer?.getData("text/html"),
        //   dragEvent.dataTransfer?.getData("audio-id"),
        // );

        let audioId = dragEvent.dataTransfer?.getData("audio-id");
        if (!audioId) return false;

        // close the recorder if open
        const setState = useEditorToolbarStore.getState().setState;
        setState("closed");

        // upload the audio file
        uploadCachedAudio(songId, audioId);
      },
    },
  });

export const AudioDropHandler = Extension.create({
  name: "audioDrop",
  addOptions() {
    return {
      songId: undefined,
    };
  },
  addProseMirrorPlugins() {
    return [
      AudioDropPlugin({
        key: new PluginKey(this.name),
        songId: this.options.songId,
      }),
    ];
  },
});
