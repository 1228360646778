import { useState } from "react";
import { Dropdown } from "@songleaf/ui";
import { DropdownItem } from "@songleaf/ui/src/dropdown/dropdown-composed";
import { GripVertical, Plus } from "lucide-react";

export type NodeMenuProps = {
  parentType: "section" | "line" | "inline-audio" | "chord-line";
  dropdownItems: DropdownItem[];
  plusAction?: () => void;
};

export const NodeMenu: React.FC<NodeMenuProps> = ({
  parentType,
  dropdownItems,
  plusAction,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${parentType}-node-menu node-menu`}>
      <button
        className="node-handle focus-visible:outline-none"
        contentEditable={false}
        draggable
        data-drag-handle=""
        onClick={() => setIsOpen(true)}
      >
        {/* <Tooltip.Root
          content={
            <>
              Drag to reorder
              <br />
              Click to open menu
            </>
          }
        > */}
        <GripVertical size={22} />
        {/* </Tooltip.Root> */}
      </button>

      <Dropdown.Composed
        items={dropdownItems}
        trigger={<div className="node-menu-trigger" />}
        rootProps={{
          open: isOpen,
          onOpenChange: setIsOpen,
        }}
      />

      {plusAction && (
        <button
          className="node-plus"
          contentEditable={false}
          onClick={() => plusAction()}
        >
          <Plus size={22} />
        </button>
      )}
    </div>
  );
};
