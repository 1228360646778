import React from "react";
import classnames from "classnames";
import * as RadixSelect from "@radix-ui/react-select";
import Select from "../select";

export interface InputProps {
  value: string;
  setValue: (value: string) => void;
}

// type Accidental = "#" | "b" | "n";
export const keyRoots = [
  "Ab",
  "A",
  "A#",
  "Bb",
  "B",
  "C",
  "C#",
  "Db",
  "D",
  "D#",
  "Eb",
  "E",
  "F",
  "F#",
  "Gb",
  "G",
  "G#",
] as const;
type Scale = "M" | "m";

export const KeyInput: React.FC<InputProps> = ({ value, setValue }) => {
  // const [accidental, setAccidental] = React.useState<Accidental>("n");
  // const [scale, setScale] = React.useState<Scale>("M");
  // const [key, setKey] = React.useState<string>("C");
  const scale = (value?.slice(-1) as Scale) === "m" ? "m" : "";
  const key = value.includes("m") ? value?.slice(0, -1) : value;

  const setKey = (k: string) => {
    setValue(`${k}${scale}`);
  };
  const setScale = () => {
    const s = scale === "m" ? "" : "m";
    setValue(`${key}${s}`);
  };

  return (
    <div className="key-input group/key-input flex items-center justify-between rounded-lg bg-surface-level-2 py-0.5 pl-2 pr-0.5">
      <label className="inline-block text-xs font-medium text-djent-400">
        Key
      </label>
      <div className="w-1" />
      <Select.Root defaultValue="C" value={key} onValueChange={setKey}>
        <SelectTrigger />

        <Select.Content>
          <Select.Viewport>
            {keyRoots.map((key) => (
              <SelectItem key={key} value={key} title={key} />
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Root>

      {/* <button
        className="mb-0.5 rounded-lg bg-surface-level-2 py-0.5 text-sm hover:bg-surface-level-3"
        onClick={() =>
          setAccidental((a) => {
            if (a === "n") return "#";
            if (a === "#") return "b";
            return "n";
          })
        }
      >
        {accidental === "#" ? "♯" : accidental === "b" ? "♭" : "♮"}
      </button> */}
      <button
        className="rounded-md bg-surface-level-2 p-1 text-xs hover:bg-surface-level-3"
        onClick={setScale}
      >
        {scale === "m" ? "minor" : "Major"}
      </button>
    </div>
  );
};

KeyInput.displayName = "KeyInput";

const SelectTrigger = () => {
  return (
    <RadixSelect.Trigger className="flex items-center justify-between rounded-md bg-surface-level-2 px-1.5 py-1 text-xs font-semibold transition-colors hover:bg-surface-level-3 focus-visible:outline-none rx-state-open:bg-surface-level-3">
      <RadixSelect.Value placeholder="C" />
    </RadixSelect.Trigger>
  );
};

interface SelectItemProps {
  value: string;
  title: string;
}
const SelectItem: React.FC<SelectItemProps> = ({ value, title }) => {
  const classes = classnames(
    `adiago-select-item py-1 px-2 flex w-full items-center justify-between space-x-1.5 relative text-sm rounded-sm`,
    `transition duration-75 text-text-primary cursor-pointer focus:outline-none`,
    `hover:bg-surface-level-2 rx-disabled:text-text-primary-2 rx-disabled:pointer-events-none`,
  );
  return (
    <RadixSelect.Item value={value} className={classes}>
      <RadixSelect.ItemText>{title}</RadixSelect.ItemText>
      <RadixSelect.ItemIndicator className="h-1.5 w-1.5 rounded-full bg-leaf-500"></RadixSelect.ItemIndicator>
    </RadixSelect.Item>
  );
};
