import { Audio } from "@songleaf/ui";
import type { OnRecordEndFn } from "@songleaf/ui/src/audio/types";
import { useState } from "react";
import { useCurrentSong } from "@/api/songs";
import { GripVertical, Undo2 } from "lucide-react";
import { useEditorToolbarStore } from "@/state/editor-toolbar";
import { useAudioStore } from "@/state/audio";
import { Cache } from "@/_utils/cache/cache";

const el = new Image();
el.src = "/assets/audio-file-preview.png";

export interface AudioTrackBoxRecorderProps {
  exit?: () => void;
}

const mimeType = "audio/wav";

export const EditorToolbarRecorder: React.FC<AudioTrackBoxRecorderProps> = ({
  exit,
}) => {
  const { songId } = useCurrentSong();
  const { setTemporaryAudioId } = useEditorToolbarStore();
  const { preferredMic } = useAudioStore();

  const [recording, setRecording] = useState<
    Parameters<OnRecordEndFn>[0] & { uuid: string }
  >();

  const handleRecordEnd: OnRecordEndFn = async (result) => {
    if (!result || !songId) {
      exit?.();
      return;
    }
    const uuid = crypto.randomUUID();

    setTemporaryAudioId(uuid);

    // cache the recording
    Cache.Audio.set([songId, uuid], result.blob);

    setRecording({ ...result, uuid });
  };

  const onDragStart = (ev: any) => {
    if (!recording) return;

    const value = `<inline-audio data-id="${recording.uuid}" data-new="true"></inline-audio>`;
    ev.dataTransfer.setData("text/html", value);
    ev.dataTransfer.setData("audio-id", recording.uuid);

    ev.dataTransfer.setDragImage(el, -10, -10);
  };

  return (
    <div
      className="group flex w-full flex-col justify-between space-y-2 rounded-lg bg-surface-base shadow-xs drop-shadow-lg transition-all ease-out animate-in fade-in slide-in-from-bottom-3"
      contentEditable={false}
    >
      {recording ? (
        <div className="flex w-full flex-col space-y-2 rounded-lg px-3 py-1.5">
          <div className="flex w-full justify-between">
            <div className="flex-1" />
            <div
              className="flex flex-shrink cursor-grab items-center space-x-1 rounded-md px-1.5 py-1 text-xs text-text-primary-3 transition-colors hover:bg-surface-level-2"
              draggable
              onDragStart={onDragStart}
            >
              <GripVertical size={12} />
              <p>Drag to song</p>
              <GripVertical size={12} />
            </div>
            <div className="-mr-1 ml-1 flex flex-1 justify-end">
              <button
                className="flex items-center justify-between space-x-1 rounded bg-surface-base px-2 text-xs font-medium text-text-primary-3 transition-colors hover:bg-surface-level-2"
                onClick={() => {
                  Cache.Audio.del(recording.uuid);
                  setRecording(undefined);
                }}
              >
                <Undo2 size={12} className="mb-[2px]" />
                <span className="mt-[1px]">Retake</span>
              </button>
            </div>
          </div>

          <Audio.OmniPlayer url={recording.url} />
        </div>
      ) : (
        <Audio.OmniRecorder
          onRecordEnd={handleRecordEnd}
          blobEncoding={mimeType}
          preferredDeviceId={preferredMic}
        />
      )}
    </div>
  );
};
