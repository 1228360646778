export const keyRootsNatural = ["A", "B", "C", "D", "E", "F", "G"] as const;

export type keyRootsNatural = (typeof keyRootsNatural)[number];

export const keyRootsFlat = ["Ab", "Bb", "Db", "Eb", "Gb"] as const;

export type KeyRootFlat = (typeof keyRootsFlat)[number];

export const keyRootsSharp = ["A#", "C#", "D#", "F#", "G#"] as const;

export type KeyRootSharp = (typeof keyRootsSharp)[number];

export const keyAccidentals = ["", "b", "#"] as const;

export type KeyAccidental = (typeof keyAccidentals)[number];

export const keyRoots = [
  "Ab",
  "A",
  "A#",
  "Bb",
  "B",
  "C",
  "C#",
  "Db",
  "D",
  "D#",
  "Eb",
  "E",
  "F",
  "F#",
  "Gb",
  "G",
  "G#",
] as const;
export const keyRootsSharps = [
  "A",
  "A#",
  "B",
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
] as const;
export const keyRootsFlats = [
  "Ab",
  "A",
  "Bb",
  "B",
  "C",
  "Db",
  "D",
  "Eb",
  "E",
  "F",
  "Gb",
  "G",
] as const;

export type KeyRoot = (typeof keyRoots)[number];

export const keyScale = ["", "m"] as const;

export type KeyScale = (typeof keyScale)[number];

export type Key = `${KeyRoot}${KeyScale}`;

export const keys = keyRoots.flatMap((root) =>
  keyScale.map((scale) => `${root}${scale}` as Key),
);

export const majorScaleIntervals = [0, 2, 2, 1, 2, 2, 2, 1];
export const majorScaleMinorChordIndexes = [1, 2, 5];
export const majorScaleDimChordIndexes = [6];

export const minorScaleIntervals = [0, 2, 1, 2, 2, 1, 2, 2];
export const minorScaleMinorChordIndexes = [0, 3, 4];
export const minorScaleDimChordIndexes = [1, 6];

export const getScaleNotes = (key: Key) => {
  const scale = key.slice(-1) === "m" ? "minor" : "major";
  const root = scale === "minor" ? key.slice(0, -1) : key;

  let intervalSet = majorScaleIntervals;
  let minorChordIndexes = majorScaleMinorChordIndexes;
  let dimChordIndexes = majorScaleDimChordIndexes;
  if (scale === "minor") {
    intervalSet = minorScaleIntervals;
    minorChordIndexes = minorScaleMinorChordIndexes;
    dimChordIndexes = minorScaleDimChordIndexes;
  }

  let noteSet: typeof keyRootsFlats | typeof keyRootsSharps = keyRootsFlats;
  if (root.slice(1) === "#") {
    noteSet = keyRootsSharps;
  }

  const notes: string[] = [];

  let currentNote = root;

  intervalSet.forEach((interval, i) => {
    currentNote =
      noteSet[
        (noteSet.indexOf(currentNote as any) + interval) % noteSet.length
      ];

    notes.push(
      currentNote +
        (minorChordIndexes.includes(i) ? "m" : "") +
        (dimChordIndexes.includes(i) ? "dim" : ""),
    );
  });
  return notes;
};
