import { useCreateSong } from "../api/songs";

export const HomePage = () => {
  const { mutate: createSong, isLoading: createSongLoading } = useCreateSong();

  if (createSongLoading) {
    return (
      <div className="adiago-layout flex h-full w-full items-center justify-center">
        loading...
      </div>
    );
  }

  return (
    <div className="adiago-layout flex h-full w-full items-center justify-center">
      <p>
        select a song or{" "}
        <span
          className="cursor-pointer text-text-brand underline"
          onClick={() => createSong({ title: "Untitled" })}
        >
          create a new song
        </span>
      </p>
    </div>
  );
};
