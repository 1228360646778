import { useNavigate, useParams } from "react-router-dom";
import { trpc } from "../_utils/trpc";
import { useCurrentUser } from "./users";

export const useCurrentSong = () => {
  const { songId } = useParams();
  const navigate = useNavigate();
  const { data, failureReason, ...rest } = trpc.song.byId.useQuery(
    (songId as string) || "",
    {
      enabled: !!songId,
      // keed an eye on this
      // tried to solve a thing I was seeing where the stem server kept reconnecting every
      // 30s or so, but it didn't work
      staleTime: Infinity,
    },
  );

  if (failureReason?.data?.httpStatus === 404) {
    setTimeout(() => {
      navigate("/");
    }, 0);
  }

  return {
    ...rest,
    songId,
    song: data?.song,
    role: data?.role,
  };
};

export const useAllSongs = () => {
  return trpc.song.all.useQuery();
};

export const useCreateSong = () => {
  const { data: user } = useCurrentUser();
  const navigate = useNavigate();
  const tutils = trpc.useContext();

  return trpc.song.create.useMutation({
    onSuccess(song) {
      if (!song || !user) return;
      const prev = tutils.song.all.getData();
      tutils.song.all.setData(undefined, [
        {
          id: song.id,
          title: song.title,
          key: song.key,
          originalCreatorId: user?.id,
          artworkId: song.artworkId,
          writers: user?.fullName,
        },
        ...(prev ?? []),
      ]);
      // may need to make this a callback if used elsewhere
      navigate(`/song/${song.id}`);
    },
  });
};

export const useUpdateSong = (songId = "") => {
  const tutils = trpc.useContext();
  return trpc.song.update.useMutation({
    onMutate: (newSong) => {
      const prev = tutils.song.byId.getData(songId);
      if (!prev) {
        console.log("no prev");

        return;
      }
      tutils.song.byId.setData(newSong.songId, {
        ...prev,
        song: {
          ...prev.song,
          title: newSong.title ?? prev.song.title,
          artworkId: newSong.artworkId ?? prev.song.artworkId,
          key: newSong.key ?? prev.song.key,
        },
      });
      const prevAll = tutils.song.all.getData();
      if (!prevAll) {
        console.log("no prev all");

        return;
      }
      const prevItem = prevAll.find((s) => s.id === newSong.songId);
      if (!prevItem) {
        console.log("no prev item");
        return;
      }
      tutils.song.all.setData(undefined, [
        {
          ...prevItem,
          title: newSong.title ?? prevItem.title,
          artworkId: newSong.artworkId ?? prevItem.artworkId,
        },
        ...(prevAll.filter((s) => s.id !== newSong.songId) ?? []),
      ]);
    },
  });
};

export const useDeleteSong = (songId = "") => {
  const tutils = trpc.useContext();
  const navigate = useNavigate();

  return trpc.song.delete.useMutation({
    onMutate(deletedSongId) {
      const prev = tutils.song.all.getData();
      tutils.song.all.setData(
        undefined,
        prev?.filter((s) => s.id !== deletedSongId),
      );

      console.log("onMutate", deletedSongId, songId);

      if (songId === deletedSongId) {
        navigate("/");
      }
    },
  });
};

export const useGetTipTapToken = (songId: string) => {
  return trpc.auth.getTipTapToken.useQuery(songId, {
    cacheTime: Infinity,
  });
};
