import { toast } from "@songleaf/ui";
import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: (error) => {
        console.error(error);
        toast.error("An error occurred. Please try again.");
      },
    },
  },
});
