import { ChevronLeft, ListMusic } from "lucide-react";
import { UserProfile } from "@/components/auth/user-profile";
import { useSidebarStore } from "@/state/sidebar";
import { useUnreadNotificationCount } from "@/api/notifications";
import { NotificationIcon } from "@/components/icons/notification";

export const HomeSidebarHeader = () => {
  const { close: closeSidebar, screen, setScreen } = useSidebarStore();
  const { data: count } = useUnreadNotificationCount();

  return (
    <>
      <div className="h-3" />
      {/* Header */}
      <div className="flex items-center justify-between">
        <div
          onClick={closeSidebar}
          className="mr-2 cursor-pointer rounded-md p-1 text-text-secondary transition-colors hover:bg-surface-level-2 hover:text-text-primary"
        >
          <ChevronLeft size={16} />
        </div>

        <UserProfile />

        <div className="flex-1" />

        <button
          className="relative cursor-pointer rounded-md p-1 hover:bg-surface-level-2"
          onClick={() => {
            screen === "song-list"
              ? setScreen("notifications")
              : setScreen("song-list");
          }}
        >
          <>
            {screen === "song-list" ? (
              <NotificationIcon scale={0.675} />
            ) : (
              <ListMusic size={16} />
            )}
            {screen === "song-list" && count ? (
              <div className="absolute top-0 right-0 flex h-3 items-center justify-center rounded-full bg-rose-500 pl-[4px] pr-1 text-[8px] text-text-invert">
                {count}
              </div>
            ) : null}
          </>
        </button>
      </div>
    </>
  );
};
