import { Link, Outlet, useNavigate } from "react-router-dom";
import { Loader, Sidebar } from "@songleaf/ui";

import { trpc } from "../../_utils/trpc";
import { Home } from "lucide-react";

export const MatrixLayout = () => {
  const navigate = useNavigate();
  const { data: check, isLoading: checkLoading } =
    trpc.theMatrix.wakeUp.useQuery();

  if (checkLoading) {
    return (
      <div className="adiago-layout flex h-full w-full items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (!check) {
    navigate("/");
  }

  return (
    <div className="adiago-layout h-full">
      <div className="adiago-layout-body flex h-full w-full">
        <Sidebar.Root>
          <Sidebar.Content className="h-full px-4 py-4">
            <p className="ml-8 mt-1.5 mb-4">follow the white rabbit</p>
            <Link to="/">
              <Home size={12} />
            </Link>

            <Sidebar.ListItem>
              <Link to="/matrix/db">db</Link>
            </Sidebar.ListItem>
            <Sidebar.ListItem>
              <Link to="/matrix/notifications">notifications</Link>
            </Sidebar.ListItem>
          </Sidebar.Content>
        </Sidebar.Root>
        <div className="adiago-layout-view z-0 h-full w-full p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
